import { useEffect, useState, useRef } from 'react'
import shuffleArray from '../utils/shuffleArray'
import { heatedBaseGameDeck, Card, testCards } from '../utils/cards'
import { io, Socket } from 'socket.io-client'
import queryString from 'query-string'
import useSound from 'use-sound'
import {
    DndContext,
    pointerWithin,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
    DragOverlay,
} from '@dnd-kit/core';
import { arrayMove, sortableKeyboardCoordinates } from '@dnd-kit/sortable'
import 'bootstrap/dist/css/bootstrap.min.css';
import MusicNoteOutlinedIcon from '@mui/icons-material/MusicNoteOutlined';
import MusicOffOutlinedIcon from '@mui/icons-material/MusicOffOutlined';
import "./css/heatedGame.css";

// todo the import errors
// @ts-expect-error
import bgMusic from '../assets/sounds/music/short-action-rock-loop-1.mp3';
// @ts-expect-error
import shufflingSound from '../assets/sounds/shuffling-cards-1.mp3'
// @ts-expect-error
import skipCardSound from '../assets/sounds/skip-sound.mp3'
// @ts-expect-error
import draw2CardSound from '../assets/sounds/draw2-sound.mp3'
// @ts-expect-error
import wildCardSound from '../assets/sounds/wild-sound.mp3'
// @ts-expect-error
import draw4CardSound from '../assets/sounds/draw4-sound.mp3'
// @ts-expect-error
import gameOverSound from '../assets/sounds/game-over-sound.mp3'
import cardBackImg from '../assets/card-back.png'
import CardActionModal from './CardActionModal';
import CurrentPlayerHand from './CurrentPlayerHand'
import OtherPlayerHand from './OtherPlayerHand'
import { TopInformationBar } from './TopInformationBar'
import { DropZone } from './DropZone'

export type User = { id: string, name: string, room: string, hand: Card[] }

type GameState = {
    winner: string;
    turn: string;
    users: User[];
    currentColor: string;
    playedCardsPile: Card[];
    drawCardPile: Card[];
    orderOfPlay: string;
    slapState: boolean;
    slapTracker: { id: string }[];
    fairPlay: { id: string, amount: number, color: string };
    apocalypse: {
        active: boolean,
        color: string,
        tracker: { id: string, name: string, canDiscard: boolean, actionTaken: boolean }[]
    };
    isHeated: {
        active: boolean,
        tracker: { id: string, name: string, saidHeated: boolean }[]
    };
    cardTargetUsers: string[];
    noThanks: {
        active: boolean,
        tracker: { id: string, name: string, canBlock: boolean, actionTaken: boolean }[]
    };
    stacking: {
        active: boolean,
        drawAmount: number,
        snowBallEffect: boolean,
        stackCount: number,
        tracker: { id: string, name: string, canStack: boolean }[]
    };
}

type StackingType = {
    active: boolean;
    drawAmount: number;
    snowBallEffect: boolean;
    stackCount: number;
    tracker: { id: string; name: string; canStack: boolean }[];
};

let socket: Socket;
let ENDPOINT = "wss://play-heated-online-server.glitch.me/"

if (window.location.hostname === "localhost") {
    ENDPOINT = 'http://localhost:5000'
}


const HeatedGame = (props: { location: { search: string } }) => {
    const data = queryString.parse(props.location.search)

    //initialize socket state
    const [room, setRoom] = useState(data.roomCode)
    const [roomFull, setRoomFull] = useState(false)
    const [users, setUsers] = useState<User[]>([])
    const [currentUser, setCurrentUser] = useState<User>({} as User)
    const [modalType, setModalType] = useState<"ghostPepper" | "fairPlay" | "gettingHeated" | "apocalypse" | "colorPicker" | "swap" | null>(null);

    //initialize game state
    const [gameOver, setGameOver] = useState(false)
    const [winner, setWinner] = useState('')
    const [turn, setTurn] = useState('')
    const [currentColor, setCurrentColor] = useState('')
    const [povPlayerIndex, setPovPlayerIndex] = useState(-1);
    const [povPlayerHand, setPovPlayerHand] = useState<Card[]>([])
    const [playedCardsPile, setPlayedCardsPile] = useState<Card[]>([])
    const [drawCardPile, setDrawCardPile] = useState<Card[]>([])
    const [orderOfPlay, setOrderOfPlay] = useState('');
    const [slapState, setSlapState] = useState(false);
    const [slapTracker, setSlapTracker] = useState<{ id: string }[]>([]);
    const [fairPlay, setFairPlay] = useState<{
        id: string,
        amount: number,
        color: string
    }>
        ({ id: '', amount: 0, color: '' });
    const [apocalypse, setApocalypse] = useState<{
        active: boolean,
        color: string,
        tracker: { id: string, name: string, canDiscard: boolean, actionTaken: boolean }[]
    }>
        ({ active: false, color: '', tracker: [] });
    const [isHeated, setIsHeated] = useState<{
        active: boolean,
        tracker: { id: string, name: string, saidHeated: boolean }[]
    }>
        ({ active: false, tracker: [] });
    const [cardTargetUsers, setCardTargetUsers] = useState<string[]>([]);
    const [noThanks, setNoThanks] = useState<{
        active: boolean,
        tracker: { id: string; name: string; canBlock: boolean, actionTaken: boolean }[]
    }>
        ({ active: false, tracker: [] });
    const [stacking, setStacking] = useState<{
        active: boolean,
        drawAmount: number,
        snowBallEffect: boolean,
        stackCount: number,
        tracker: { id: string, name: string, canStack: boolean }[]
    }>
        ({ active: false, drawAmount: 0, snowBallEffect: false, stackCount: 0, tracker: [] });
    const [dragAndDropOverlayImg, setDragAndDropOverlayImg] = useState("");
    const [offeredCards, setOfferedCards] = useState<{ playerId: string; cardId: string; }[]>([]);
    const [hasOfferedCardBeenAcceptedOrRejectedOnThisTurn, setHasOfferedCardBeenAcceptedOrRejectedOnThisTurn] = useState(false);
    const [cardEffectIsBeingResolved, setCardEffectIsBeingResolved] = useState(false);

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 15,
            },
        }),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    const [isSoundMuted, setSoundMuted] = useState(false)
    const [isMusicMuted, setMusicMuted] = useState(true);

    const [playBgMusic, { stop: stopBgMusic }] = useSound(bgMusic, { soundEnabled: !isMusicMuted, interrupt: true, loop: true })
    const [playShufflingSound] = useSound(shufflingSound)
    const [playSkipCardSound] = useSound(skipCardSound)
    const [playDraw2CardSound] = useSound(draw2CardSound)
    const [playWildCardSound] = useSound(wildCardSound)
    const [playDraw4CardSound] = useSound(draw4CardSound)
    const [playGameOverSound] = useSound(gameOverSound)

    const topOfDiscardPileRef = useRef<any>();
    const drawnCardRef = useRef<any>();
    const noThanksRef = useRef(noThanks);
    const stackingRef = useRef(stacking);

    useEffect(() => {
        noThanksRef.current = noThanks;
    }, [noThanks]);

    useEffect(() => {
        stackingRef.current = stacking;
    }, [stacking]);

    useEffect(() => {
        setStacking(updateStackingState());
        setOfferedCards([]);
        setHasOfferedCardBeenAcceptedOrRejectedOnThisTurn(false);
        setCardEffectIsBeingResolved(false);
    }, [turn]);

    useEffect(() => {
        if (povPlayerIndex !== -1) {
            setPovPlayerHand(users[povPlayerIndex].hand);
        }
    }, [povPlayerIndex, povPlayerIndex !== -1 && users[povPlayerIndex].hand])

    useEffect(() => {
        for (let i = 0; i < users.length; i++) {
            if (currentUser.id === users[i].id) {
                setPovPlayerIndex(i);
            }
        }
    }, [users.length, currentUser])

    // reshuffle when we get low of cards in the draw pile (sfx needed)
    useEffect(() => {
        // we don't need to reshuffle when we haven't drawn or played cards yet
        // checks on less than 46 cards, because 45 cards is the most that can be drawn at once in a usage of "Ghost Pepper Spray" in a 10 player game
        if (drawCardPile.length !== 0 && playedCardsPile.length !== 0 && drawCardPile.length < 46) {
            const cardsToGoOnBottomOfDrawPile = [...playedCardsPile];
            const lastPlayedCard = cardsToGoOnBottomOfDrawPile.pop()!;

            updateGameState({
                winner: currentUser.id,
                drawCardPile: shuffleArray(cardsToGoOnBottomOfDrawPile).concat(drawCardPile),
                playedCardsPile: [lastPlayedCard]
            });
        }
    }, [drawCardPile.length])



    // this runs on change to the number of users in the room
    useEffect(() => {
        // if there are less than 2 users, don't prepare the game
        if (users.length < 2) {
            return;
        }
        //shuffle cards
        let shuffledHeatedDeck: Card[] = shuffleArray(heatedBaseGameDeck)

        //extract random card from shuffledCards
        let firstCard: Card = shuffledHeatedDeck.pop()!;
        // check if the first card is not an action card so the first card played is easy
        // if the type of the card is a number, we're done and work with it!
        while (firstCard.type !== "Number") {
            // if it's not a number, we are putting it at the front of the deck
            // so that we can continue searching from the back, where .pop() removes from
            shuffledHeatedDeck = [firstCard].concat(shuffledHeatedDeck)

            // The '!' after the .pop() means "trust me, I know this is never going to be undefined", because when we init the game, we always use a full deck
            firstCard = shuffledHeatedDeck.pop()!;
        }

        // played cards pile is going to start with the first card
        const playedCardsPile: Card[] = [firstCard];

        const noThanksState: { id: string, name: string, canBlock: boolean, actionTaken: boolean }[] = [];
        const stackingState: { id: string, name: string, canStack: boolean }[] = [];

        // give every player 7 cards in hand
        for (let i = 0; i < users.length; i++) {
            users[i].hand = shuffledHeatedDeck.splice(0, 7);
            // users[i].hand.push(testCards["Blue Too Hot"]);

            // This loop constructs the start game noThanksState array
            noThanksState.push({
                id: users[i].id,
                name: users[i].name,
                canBlock: users[i].hand.some(card => card.name === 'No, Thanks'),
                actionTaken: false
            })

            // Initialize stacking state with 'canStack' set to false
            stackingState.push({
                id: users[i].id,
                name: users[i].name,
                canStack: false
            })
        }

        //store all remaining cards into drawCardPile
        const drawCardPile = shuffledHeatedDeck;

        for (let i = 0; i < users.length; i++) {
            if (currentUser.id === users[i].id) {
                setPovPlayerIndex(i);
                setPovPlayerHand(users[i].hand)
            }
        }


        //send initial state to server
        socket.emit('initGameState', {
            gameOver: false,
            turn: users[0].id,
            users: users,
            currentColor: firstCard.color,
            playedCardsPile: playedCardsPile,
            drawCardPile: drawCardPile,
            orderOfPlay: "standard",
            slapState: false,
            slapTracker: [],
            fairPlay: { id: '', amount: 0, color: '' },
            apocalypse: { active: false, color: '', tracker: [] },
            isHeated: { active: false, tracker: [] },
            cardTargetUsers: [],
            noThanks: { active: false, tracker: noThanksState },
            stacking: { active: false, drawAmount: 0, snowBallEffect: false, stackCount: 0, tracker: stackingState }
        })
    }, [users.length])

    // this only runs once on component initialization
    useEffect(() => {

        const connectionOptions = {
            "forceNew": true,
            "reconnectionAttempts": "Infinity",
            "timeout": 10000,
            "transports": ["websocket"]
        }
        // todo connect doesn't exist on io??
        // @ts-expect-error
        socket = io.connect(ENDPOINT, connectionOptions)
        // socket = socket.connect(ENDPOINT, connectionOptions)

        socket.emit('join', { room: room },
            (error: string) => {
                if (error)
                    setRoomFull(true)
            }
        )

        socket.on('initGameState', ({ turn, users, currentColor, playedCardsPile, drawCardPile, orderOfPlay, slapState, slapTracker, fairPlay, apocalypse, isHeated, cardTargetUsers, noThanks, stacking }: GameState) => {
            setGameOver(false)
            setTurn(turn)
            setCurrentColor(currentColor)
            setPlayedCardsPile(playedCardsPile)
            setDrawCardPile(drawCardPile)
            setUsers(users)
            setOrderOfPlay(orderOfPlay)
            setSlapState(slapState);
            setSlapTracker(slapTracker);
            setFairPlay(fairPlay);
            setApocalypse(apocalypse);
            setIsHeated(isHeated);
            setCardTargetUsers(cardTargetUsers);
            setNoThanks(noThanks);
            setStacking(stacking);
        })

        socket.on('updateGameState', ({ winner, turn, users, currentColor, playedCardsPile, drawCardPile, orderOfPlay, slapState, slapTracker, fairPlay, apocalypse, isHeated, cardTargetUsers, noThanks, stacking }: GameState) => {
            users && setUsers(users);
            setGameOver(isGameOver(users));
            gameOver === true && playGameOverSound();
            winner && setWinner(winner);
            turn && setTurn(turn);
            currentColor && setCurrentColor(currentColor);
            playedCardsPile && setPlayedCardsPile(playedCardsPile);
            drawCardPile && setDrawCardPile(drawCardPile);
            orderOfPlay && setOrderOfPlay(orderOfPlay);
            slapState && setSlapState(slapState);
            slapTracker && setSlapTracker(slapTracker);
            fairPlay && setFairPlay(fairPlay);
            apocalypse && setApocalypse(apocalypse);
            isHeated && setIsHeated(isHeated);
            cardTargetUsers && setCardTargetUsers(cardTargetUsers);
            noThanks && setNoThanks(noThanks);
            stacking && setStacking(stacking);

            if (playedCardsPile.length === 0) {
                setCardEffectIsBeingResolved(false);
            };
            const lastPlayedCard = playedCardsPile[playedCardsPile.length - 1];
            if (
                (lastPlayedCard.name === "Swap" && modalType !== null) || (lastPlayedCard.name === "Too Hot" && slapState) || (lastPlayedCard.name === "Adjust Spice Level" && modalType !== null) || (lastPlayedCard.name === "Draw Four" && modalType !== null) || (lastPlayedCard.name === "Getting Heated" && modalType !== null) || (lastPlayedCard.name === "Apocalypse" && apocalypse.active) || (lastPlayedCard.name === "Fair Play" && fairPlay.id !== '') || (lastPlayedCard.name === "Ghost Pepper Spray" && modalType !== null)
            ) {
                setCardEffectIsBeingResolved(true);
            }
        })

        socket.on('slapTrackerUpdate', (updatedSlapTracker: { id: string }[]) => {
            // Update the slapTracker state with the updated slapTracker received from the server
            setSlapTracker(updatedSlapTracker);
        });

        socket.on('slapStateUpdate', (updatedSlapState: boolean) => {
            // Update the slapState with the updated slapState received from the server
            setSlapState(updatedSlapState);
        });

        socket.on("roomData", ({ users }: { users: User[] }) => {
            setUsers(users)
        })

        socket.on('currentUserData', ({ user }: { user: User }) => {
            setCurrentUser(user);
        })

        socket.on("cardHasBeenOffered", (newOfferedCards: { playerId: string, cardId: string }[]) => {
            setOfferedCards(newOfferedCards);
        })

        socket.on("offeredCardHasBeenAccepted", () => {
            // this makes sure that someone can't get help more than once per turn
            setOfferedCards([]);
            setHasOfferedCardBeenAcceptedOrRejectedOnThisTurn(true)
        })

        socket.on("cardEffectIsBeingResolved", () => {
            setCardEffectIsBeingResolved(true);
        })

        //cleanup on component unmount
        return function cleanup() {
            socket.disconnect()
            //shut down connnection instance
            socket.removeAllListeners()
        }

    }, [])

    // for the background music
    useEffect(() => {
        if (isMusicMuted) {
            stopBgMusic();
        }
        else {
            playBgMusic();
        }
    }, [isMusicMuted])

    const updateGameState = (updatedValues: Partial<GameState>) => {
        const currentGameState = {
            winner,
            turn,
            playedCardsPile,
            users,
            currentColor,
            drawCardPile,
            orderOfPlay,
            slapState,
            slapTracker,
            fairPlay,
            apocalypse,
            isHeated,
            cardTargetUsers,
            noThanks,
            stacking
        };
        const newState = { ...currentGameState, ...updatedValues };
        socket.emit('updateGameState', newState);
    }

    const onHideColorPicker = (selectedColor: string) => {
        setModalType(null);
        const playedCard = playedCardsPile[playedCardsPile.length - 1];
        let nextPlayerId = getNextPlayerId();
        const updatedStacking = stackingRef.current;
        updateGameState({
            winner: currentUser.name,
            currentColor: selectedColor,
            cardTargetUsers: [nextPlayerId],
            stacking: updatedStacking
        })
        if (playedCard.name === "Draw Four") {
            setCardTargetUsers([nextPlayerId]);
            if (updatedStacking.active || updatedStacking.snowBallEffect) {
                snowBallDelayEffect(nextPlayerId, selectedColor, updatedStacking, (userId: string) => {
                    if (userId === nextPlayerId) {
                        nextPlayerId = getNextPlayerId();
                        makePlayerDrawCards(nextPlayerId, updatedStacking.drawAmount)
                        updateGameState({
                            winner: currentUser.name,
                            turn: nextPlayerId,
                            currentColor: selectedColor,
                            cardTargetUsers: [nextPlayerId],
                            noThanks: { ...noThanks, active: false },
                            stacking: { ...updatedStacking, snowBallEffect: false, stackCount: 0, drawAmount: 0 }
                        })
                    }
                })
            } else {
                noThanksBlockEffect([nextPlayerId], selectedColor, (userId: string) => {
                    if (userId === nextPlayerId) {
                        makePlayerDrawCards(nextPlayerId, updatedStacking.drawAmount)
                        updateGameState({
                            winner: currentUser.name,
                            turn: nextPlayerId,
                            currentColor: selectedColor,
                            cardTargetUsers: [nextPlayerId],
                            stacking: updatedStacking
                        });
                    }
                })
            }
        } else {
            updateGameState({
                winner: currentUser.name,
                turn: nextPlayerId,
                currentColor: selectedColor,
                cardTargetUsers: [nextPlayerId],
                stacking: updatedStacking
            });
        }
    }

    //takes in IDs and colors selected from GettingHeatedModal.tsx
    const handleCloseGettingHeatedModal = (selectedId: string, selectedColor: string) => {
        setModalType(null);
        setCardTargetUsers([selectedId]);
        const updatedStacking = stackingRef.current;
        if (updatedStacking.active || updatedStacking.snowBallEffect) {
            snowBallDelayEffect(selectedId, selectedColor, updatedStacking, (userId: string) => {
                makePlayerDrawCards(selectedId, updatedStacking.drawAmount)
                updateGameState({
                    winner: currentUser.name,
                    turn: getNextPlayerId(),
                    currentColor: selectedColor,
                    cardTargetUsers: [selectedId],
                    noThanks: { ...noThanks, active: false },
                    stacking: { ...updatedStacking, snowBallEffect: false, stackCount: 0, drawAmount: 0 }
                })
            })
        } else {
            noThanksBlockEffect([selectedId], selectedColor, (userId: string) => {
                if (userId === selectedId) {
                    makePlayerDrawCards(selectedId, updatedStacking.drawAmount);
                    updateGameState({
                        winner: currentUser.name,
                        turn: getNextPlayerId(),
                        currentColor: selectedColor,
                        cardTargetUsers: [selectedId],
                        stacking: updatedStacking
                    })
                }
            })
        }
    }

    //takes in IDs, # of cards to draw, and color selected from GhostPepperModal.tsx
    const handleCloseGhostPepperModal = (drawSelections: { [playerId: string]: number }, selectedColor: string) => {
        setModalType(null);
        const updatedStacking = updateStackingState();
        const drawSelectionsKeys = Object.keys(drawSelections);
        setCardTargetUsers(drawSelectionsKeys);
        noThanksBlockEffect(drawSelectionsKeys, selectedColor, (userId: string) => {
            makePlayerDrawCards(userId, drawSelections[userId]);
            updateGameState({
                winner: currentUser.name,
                turn: getNextPlayerId(),
                currentColor: selectedColor,
                cardTargetUsers: drawSelectionsKeys,
                stacking: updatedStacking
            })
        })
    }

    //takes in IDs selected from SwapModal.tsx
    const handleCloseSwapModal = (selectedId: string) => {
        setModalType(null);
        setCardTargetUsers([selectedId]);
        const updatedStacking = updateStackingState();
        noThanksBlockEffect([selectedId], currentColor, (userId: string) => {
            if (userId === selectedId) {
                makePlayerSwapHands(selectedId);
                updateGameState({
                    winner: currentUser.name,
                    turn: getNextPlayerId(),
                    stacking: updatedStacking
                })
            }
        })
    };

    //takes in IDs and colors selected from FairPlayModal.tsx
    const handleCloseFairPlayModal = (selectedId: string, selectedColor: string, discardAmount: number) => {
        setModalType(null);
        const updatedStacking = updateStackingState();
        if (discardAmount === 0) {
            updateGameState({
                winner: currentUser.name,
                turn: getNextPlayerId(),
                currentColor: selectedColor,
                stacking: updatedStacking
            });
        } else {
            setCardTargetUsers([selectedId]);
            setFairPlay(prevState => ({ ...prevState, id: selectedId, amount: discardAmount }));
            updateGameState({
                winner: currentUser.name,
                turn: currentUser.id,
                currentColor: selectedColor,
                fairPlay: { ...fairPlay, id: selectedId, amount: discardAmount, color: selectedColor },
                stacking: updatedStacking
            });
        }
    }

    //takes in color selected from ApocalypseModal.tsx
    const handleCloseApocalypseModal = (selectedColor: string) => {
        setModalType(null);
        const updatedStacking = updateStackingState();
        // Get the ids and names of all other users who are not the apocalypse card player, and set them as the apocalypse users in the tracker, and update their draw and discard properties to false.
        const otherUsersIds = users.filter(user => user.id !== currentUser.id).map(user => {
            const canDiscard = user.hand.some(card => (card.color === selectedColor) || (card.name === "No, Thanks"));
            return { id: user.id, name: user.name, canDiscard: canDiscard, actionTaken: false }
        });
        updateGameState({
            winner: currentUser.name,
            turn: currentUser.id,
            currentColor: selectedColor,
            apocalypse: { ...apocalypse, active: true, color: selectedColor, tracker: otherUsersIds },
            stacking: updatedStacking
        });
    }

    const modalCloseHandlers = {
        colorPicker: onHideColorPicker,
        gettingHeated: handleCloseGettingHeatedModal,
        swap: handleCloseSwapModal,
        ghostPepper: handleCloseGhostPepperModal,
        fairPlay: handleCloseFairPlayModal,
        apocalypse: handleCloseApocalypseModal
    };

    const isGameOver = (users: User[]): boolean => {
        for (let i = 0; i < users.length; i++) {
            if (users[i].hand.length === 0) {
                return true;
            }
        }
        return false;
    }

    const updateIsHeatedState = () => {
        let updatedTracker = [...isHeated.tracker];
        // Check and remove any users from the tracker who now have more than 1 card.
        updatedTracker = updatedTracker.filter(user => {
            const userFromTracker = users.find(trackerUser => trackerUser.id === user.id);
            return userFromTracker && userFromTracker.hand.length <= 1;
        });
        const heatedPlayer = users.find(user =>
            user.hand.length === 1 &&
            !isHeated.tracker.some(trackerUser => trackerUser.id === user.id)
        );
        if (heatedPlayer) {
            updatedTracker.push({
                id: heatedPlayer.id,
                name: heatedPlayer.name,
                saidHeated: false
            });
        }
        // If there is any user in tracker who hasn't said "Heated" yet, mark them as said "Heated"
        if (isHeated.active) {
            const trackerIndex = updatedTracker.findIndex(user => !user.saidHeated && user.id !== currentUser.id);
            if (trackerIndex !== -1) {
                updatedTracker[trackerIndex].saidHeated = true;
            }
        }
        return {
            active: !!heatedPlayer,
            tracker: updatedTracker
        };
    };


    const updateNoThanksState = (userId: string | null = null) => {
        const updatedNoThanksTracker = users.map(user => {
            // For each user, check if they have a 'No, Thanks' card in hand
            const canBlock = user.hand.some(card => card.name === 'No, Thanks');
            // Return a new object for this user, with the updated 'canBlock' property
            let actionTaken = false;
            if (userId !== null && user.id === userId) {
                actionTaken = true;
            }
            return {
                id: user.id,
                name: user.name,
                canBlock,
                actionTaken
            };
        });
        // Return the updated tracker
        return updatedNoThanksTracker;
    }

    const updateStackingState = (drawCount?: number, cardAdded?: boolean) => {
        const lastPlayedCard = playedCardsPile[playedCardsPile.length - 1];
        // Update the tracker to determine which users can play a stacking card.
        const updatedStackingTracker = users.map(user => {
            const canStack = user.hand.some(card =>
                ((card.name === lastPlayedCard.name) && (card.color === lastPlayedCard.color)) ||
                (card.name === "Draw 2" && card.name === lastPlayedCard.name && cardTargetUsers.includes(user.id)));
            return {
                id: user.id,
                name: user.name,
                canStack
            };
        });
        // Conditions to determine if stacking should be active.
        const stackingShouldBeActive = updatedStackingTracker.some(user => user.canStack) && fairPlay.id === '' && !slapState && !apocalypse.active;
        // Update the draw amount, considering both the snowball effect and any provided draw counts.
        const updatedDrawAmount = stackingRef.current.snowBallEffect && drawCount ? stackingRef.current.drawAmount + drawCount : stackingRef.current.snowBallEffect ? stackingRef.current.drawAmount : drawCount ? stackingRef.current.drawAmount + drawCount : 0;
        // Adjust the stack count based on the snowball effect and whether a card was added.
        const updatedStackCount = stackingRef.current.snowBallEffect && cardAdded ? stackingRef.current.stackCount + 1 : stackingRef.current.snowBallEffect ? stackingRef.current.stackCount : 0
        return {
            active: stackingShouldBeActive,
            drawAmount: updatedDrawAmount,
            snowBallEffect: stacking.snowBallEffect,
            stackCount: updatedStackCount,
            tracker: updatedStackingTracker
        };
    }

    const handleSnowBallUpdate = (cardName: string) => {
        if (cardName === "Draw Four") {
            if (stacking.snowBallEffect) {
                // If snowBallEffect is active then this means that a delay interval is currently active so the function is returned with updated drawCount and cardAdded parameters.
                return updateStackingState(4, true);
            } else {
                // If snowBallEffect is inactive this means there is no delay and that this is the first play, hence return the updated state with the drawCount.
                return updateStackingState(4);
            }
        } else if (cardName === "Draw 2" || cardName === "Getting Heated") {
            if (stacking.snowBallEffect) {
                return updateStackingState(2, true);
            } else {
                return updateStackingState(2);
            }
        } else {
            // If a non-snowball card is played we just update the stacking state as usual.
            return updateStackingState();
        }
    }

    const noThanksBlockEffect = (cardTargetUsers: string[], selectedColor: string, handleCardEffect: (userID: string) => void) => {
        // Separate users who can and can't block
        const canBlockUsers: string[] = [];
        const cantBlockUsers: string[] = [];
        const updatedStacking = updateStackingState();
        cardTargetUsers.forEach(userId => {
            const targetUser = noThanks.tracker.find(user => user.id === userId);
            if (targetUser && targetUser.canBlock) {
                canBlockUsers.push(userId);
            } else {
                cantBlockUsers.push(userId);
            }
        });
        // Immediately handle effect for users who can't block and go to next turn if no blockers
        if (canBlockUsers.length === 0) {
            cantBlockUsers.forEach(userId => handleCardEffect(userId));
            updateGameState({
                winner: winner,
                turn: getNextPlayerId(),
                currentColor: selectedColor,
                cardTargetUsers: canBlockUsers,
                noThanks: { ...noThanks, active: canBlockUsers.length > 0 },
                stacking: updatedStacking
            });
        } else {
            // Immediately handle effect for users who can't block and wait for blockers
            cantBlockUsers.forEach(userId => handleCardEffect(userId));
            updateGameState({
                turn: currentUser.id,
                currentColor: selectedColor,
                cardTargetUsers: canBlockUsers,
                noThanks: { ...noThanks, active: canBlockUsers.length > 0 },
                stacking: updatedStacking
            });
        }
        // If there are users who can block, start a timer that will either
        // wait for them to block or handle the card effect after 5 seconds.
        if (canBlockUsers.length > 0) {
            let elapsedTime = 0;
            const blockEffectInterval = setInterval(() => {
                elapsedTime++;
                for (let index = canBlockUsers.length - 1; index >= 0; index--) {
                    const userId = canBlockUsers[index];
                    const userActionTaken = noThanksRef.current.tracker.find(user => user.id === userId)?.actionTaken;
                    if (userActionTaken || elapsedTime >= 5) {
                        if (!userActionTaken) {
                            handleCardEffect(userId);
                        }
                        canBlockUsers.splice(index, 1);
                    }
                };
                // If all users have taken their action or 5 seconds have passed, clear the interval
                if (canBlockUsers.length === 0 || elapsedTime >= 5) {
                    clearInterval(blockEffectInterval);
                }
            }, 1000);
        }
    }

    const snowBallDelayEffect = (userId: string, selectedColor: string, stackingState: StackingType, handleCardEffect: (id: string) => void) => {
        // Keep track of the initial number of stacked cards when the delay effect starts.
        let initialStackCount = stackingRef.current.stackCount;
        // Update the stacking state to show that the snowball effect is active.
        const updatedStackingWithSnowball = { ...stackingState, snowBallEffect: true };
        // Check if any user can play a stacking card during the delay.
        const usersCanStack = stackingState.tracker.some(user => user.canStack);
        // Check if the targeted player has the ability to block the card's effect.
        const targetPlayerCanBlock = noThanks.tracker.some(user => user.id === userId && user.canBlock);
        // Update the game state to reflect the delay effect and other conditions.
        updateGameState({
            turn: getNextPlayerId(),
            currentColor: selectedColor,
            cardTargetUsers: [userId],
            noThanks: { ...noThanks, active: targetPlayerCanBlock },
            stacking: updatedStackingWithSnowball
        });
        let elapsedTime = 0;
        // Set an interval to monitor if any action is taken during the delay.
        const snowBallEffectInterval = setInterval(() => {
            elapsedTime++;
            // Check if the targeted player decided to use the 'No Thanks' option, and if so clear the interval.
            const userActionTakenNoThanks = noThanksRef.current.tracker.find(user => user.id === userId)?.actionTaken;
            if (userActionTakenNoThanks) {
                clearInterval(snowBallEffectInterval);
                return;
            }
            // If another snowball card is played during the delay, clear the interval.
            if (stackingRef.current.stackCount > initialStackCount) {
                clearInterval(snowBallEffectInterval);
                return;
            }
            // If no players can stack and the target player can't block or the maximum delay time is reached, trigger the card's effect.
            if ((!usersCanStack && !targetPlayerCanBlock) || elapsedTime >= 5) {
                clearInterval(snowBallEffectInterval);
                handleCardEffect(userId);
            }
        }, 1000);
    };

    const isThisCardAllowedToBePlayed = (playedCard: Card): boolean => {
        // can't play if it's not your turn
        // if (turn !== users[povPlayerIndex].id) {
        //     return false;
        // }
        const topCardOfDiscard = playedCardsPile[playedCardsPile.length - 1];
        // black and white cards can always be played on your turn
        if (playedCard.color === "Black" || playedCard.color === "White") {
            return true;
        }
        // cards can be played on the same color, or if the color recently changed to the color
        // after a black card was played
        if (currentColor === playedCard.color) {
            return true;
        }
        // if it's the same number card, no matter what color, it's legal to play
        if (topCardOfDiscard.type === "Number" && playedCard.type === "Number" && topCardOfDiscard.number === playedCard.number) {
            return true;
        }
        // if it's a action card, if they have the same descripion, then it's the same card in a different color
        // so it's legal to play
        if (topCardOfDiscard.type === "Action" && playedCard.type === "Action" && topCardOfDiscard.description === playedCard.description) {
            return true;
        }
        // this card can't be played right now
        return false;
    }

    const getNextPlayerId = (isReversing = false): string => {
        let nextPlayerIndex = 0;
        let direction = orderOfPlay;
        let currentTurn = turn;
        if (stacking.active && currentUser.id !== turn && !slapState) {
            currentTurn = currentUser.id;
        }
        if (isReversing) {
            if (orderOfPlay === "standard") {
                direction = "reverse"
            }
            else {
                direction = "standard"
            }
        }
        for (let u = 0; u < users.length; u++) {
            if (direction === "reverse") {
                if (users[u].id === currentTurn) {
                    if (u === 0) {
                        nextPlayerIndex = users.length - 1;
                    }
                    else {
                        nextPlayerIndex = u - 1;
                    }
                }
            }
            else {
                if (users[u].id === currentTurn) {
                    if (u === users.length - 1) {
                        nextPlayerIndex = 0;
                    }
                    else {
                        nextPlayerIndex = u + 1;
                    }
                }
            }
        }
        return users[nextPlayerIndex].id;
    }

    const skipNextPlayerId = (): string => {
        let nextPlayerIndex = 0;
        for (let u = 0; u < users.length; u++) {
            if (orderOfPlay === "reverse") {
                if (users[u].id === currentUser.id) {
                    if (u === 0) {
                        nextPlayerIndex = users.length - 2;
                    }
                    else if (u === 1) {
                        nextPlayerIndex = users.length - 1;
                    }
                    else {
                        nextPlayerIndex = u - 2;
                    }
                }
            }
            else {
                if (users[u].id === currentUser.id) {
                    if (u === users.length - 1) {
                        nextPlayerIndex = 1;
                    }
                    else if (u === users.length - 2) {
                        nextPlayerIndex = 0
                    }
                    else {
                        nextPlayerIndex = u + 2;
                    }
                }
            }
        }
        return users[nextPlayerIndex].id;
    }

    const getPlayAreaStyle = () => {
        // Check if the current user is in the apocalypse tracker
        const apocalypseUser = apocalypse.tracker.find(({ id }) => id === currentUser.id);
        // Conditions for disabling pointer events are:
        // 1. The current user is required to discard cards as part of fairPlay rules
        // 2. The apocalypse is active and the current user either is not in apocalypse tracker or has already drawn or discarded a card
        // 3. The apocalypse is not active and it's not the current user's turn
        // 4. noThanks state is active and the current user is not one of the target players
        if (slapState ||
            fairPlay.id ||
            (apocalypse.active && (!apocalypseUser || apocalypseUser.actionTaken)) ||
            (!apocalypse.active && turn !== currentUser.id) ||
            (noThanks.active && !cardTargetUsers.includes(currentUser.id))) {
            return { pointerEvents: 'none' as const };
        } else {
            return {};
        }
    }

    const getSlapButtonStyle = () => {
        const slapTrackerIncludesCurrentUser = slapTracker.some(({ id }) => id === currentUser.id);
        const isButtonDisabled = slapState && slapTrackerIncludesCurrentUser;
        const isButtonEnabled = slapState && !slapTrackerIncludesCurrentUser;

        if (isButtonDisabled) {
            return {
                disabled: true,
                style: { pointerEvents: 'none' as const }
            };
        } else if (isButtonEnabled) {
            return {
                disabled: false,
                style: { pointerEvents: 'all' as const }
            };
        } else {
            return {
                disabled: true,
                style: { pointerEvents: 'none' as const }
            };
        }
    }

    const getHeatedButtonStyle = () => {
        if (isHeated.active) {
            return {
                disabled: false,
                style: { pointerEvents: 'all' as const }
            };
        } else {
            return {
                disabled: true,
                style: { pointerEvents: 'none' as const }
            };
        }
    }

    // This function generates the text that is displayed to the player, providing information about the current game state.
    const renderPlayerText = () => {
        let result = '';
        const lastPlayedCard = playedCardsPile[playedCardsPile.length - 1];
        const currentUserCanBlock = cardTargetUsers.includes(currentUser.id);
        const userCanStack = stacking.tracker.some(user => user.id === currentUser.id && user.canStack);
        // If slapState is true, all players are instructed to hit the slap button.
        if (slapState) {
            // Check if current user has already slapped
            const currentUserHasSlapped = slapTracker.some(({ id }) => id === currentUser.id);
            if (currentUserHasSlapped) {
                result += `Too Hot! Waiting for Other Players to Slap`;
            } else {
                result += `Too Hot! Click the Slap Button`;
            }
        }
        if ((noThanksRef.current.active && currentUserCanBlock) && (turn === currentUser.id) && (stackingRef.current.snowBallEffect && userCanStack)) {
            result += `Block with No Thanks or Stack ${lastPlayedCard.name}?`;
        } else if (stackingRef.current.snowBallEffect && userCanStack) {
            result += `Stack ${lastPlayedCard.name}?`;
        } else if (noThanksRef.current.active && currentUserCanBlock) {
            result += `Block with No Thanks?`;
        }
        // If the Apocalypse card is in play, the output varies depending on the current user's status.
        if (apocalypse.active) {
            if (currentUser.id === turn) {
                const apocalypseUsers = apocalypse.tracker.filter((user) => !user.actionTaken);
                const names = apocalypseUsers.map((user) => user.name).join(' and ');
                result += `Apocalypse! Waiting for ${names}`;
            } else {
                const apocalypseUser = apocalypse.tracker.find(user => user.id === currentUser.id);
                if (apocalypseUser && (!apocalypseUser.actionTaken)) {
                    result += `Apocalypse! Discard a ${currentColor} Card or Draw`;
                } else {
                    const otherApocalypseUsers = apocalypse.tracker.filter((user) => user.id !== currentUser.id && !user.actionTaken);
                    const names = otherApocalypseUsers.map((user) => user.name).join(' and ');
                    result += `Apocalypse! Waiting for ${names}`;
                }
            }
            // If fairPlay is active and the current user has to discard cards, the user is informed about the number of cards to discard.
        } else if (fairPlay.id === currentUser.id) {
            result += ` | Cards to Discard: ${fairPlay.amount}`;
        } else {
            // If another user has to discard cards, the current user is informed about it.
            const discardingUser = users.find(user => user.id === fairPlay.id);
            if (discardingUser) {
                result += ` | Waiting for ${discardingUser.name} to Discard`;
            }
        }
        return result;
    }

    const handleCardClick = (card: Card, index: number, playedCardImageRef: any) => {
        if (fairPlay.id) {
            fairPlayDiscardHandler(card, index);
        } else if (apocalypse.active) {
            apocalypseDiscardHandler(card, index);
        } else if (noThanks.active && stacking.active) {
            if (card.name === "No, Thanks") {
                noThanksDiscardHandler(card, index);
            } else {
                stackingHandler(card, index);
            }
        } else if (stackingRef.current.active && stackingRef.current.snowBallEffect) {
            stackingHandler(card, index);
        } else if (noThanks.active) {
            noThanksDiscardHandler(card, index);
        } else if (stackingRef.current.active && (currentUser.id !== turn)) {
            stackingHandler(card, index);
        } else if (currentUser.id === turn) {
            onCardPlayedHandler(card, index, playedCardImageRef);
        }
    };

    const handleDrawCardForFacedownDeck = () => {
        if (!(apocalypse.active || turn === currentUser.id || slapState)) {
            return;
        }
        if (apocalypse.active) {
            apocalypseDrawHandler();
        } else {
            onCardDrawnHandler();
        }
    }

    const makePlayerDrawCards = (playerId: string, numberOfCards: number) => {
        for (let u = 0; u < users.length; u++) {
            if (users[u].id === playerId) {
                users[u].hand = users[u].hand.concat(drawCardPile.splice(0, numberOfCards));
            }
        }
    }

    const makePlayerSwapHands = (playerId: string) => {
        const newUsers = [...users];

        for (let u = 0; u < newUsers.length; u++) {
            if (newUsers[u].id === playerId) {
                const targetPlayerHand = newUsers[u].hand;
                const currentPlayerHand = newUsers[povPlayerIndex].hand;
                newUsers[povPlayerIndex].hand = targetPlayerHand;
                newUsers[u].hand = currentPlayerHand;
            }
        }
        setUsers(newUsers);
    };

    const stackingHandler = (playedCard: Card, cardIndexInPlayerHand: number) => {
        if (playedCardsPile.length === 0) return;
        const lastPlayedCard = playedCardsPile[playedCardsPile.length - 1];
        if (lastPlayedCard.name === "Swap" && modalType !== null) return;
        if (lastPlayedCard.name === "Too Hot" && slapState) return;
        if (lastPlayedCard.name === "Adjust Spice Level" && modalType !== null) return;
        if (lastPlayedCard.name === "Draw Four" && modalType !== null) return;
        if (lastPlayedCard.name === "Getting Heated" && modalType !== null) return;
        if (lastPlayedCard.name === "Apocalypse" && apocalypse.active) return;
        if (lastPlayedCard.name === "Fair Play" && fairPlay.id !== '') return;
        if (lastPlayedCard.name === "Ghost Pepper Spray" && modalType !== null) return;
        if ((playedCard.name === lastPlayedCard.name) &&
            (playedCard.color === lastPlayedCard.color) || (playedCard.name === lastPlayedCard.name) && (lastPlayedCard.name === "Draw 2") && cardTargetUsers.includes(currentUser.id)) {
            onCardPlayedHandler(playedCard, cardIndexInPlayerHand);
        }
    }

    const noThanksDiscardHandler = (playedCard: Card, cardIndexInPlayerHand: number) => {
        // Check if the card to be discarded is 'No, Thanks'
        if (playedCard.name !== 'No, Thanks') {
            return;
        }
        // Check if the current user is the target user and if they can block
        const currentUserCanBlock = noThanks.tracker.some(user => user.id === currentUser.id && user.canBlock);
        if (!currentUserCanBlock || !cardTargetUsers.includes(currentUser.id)) {
            return;
        }
        // The player discards the 'No, Thanks' card
        playedCardsPile.push(playedCard);
        for (let u = 0; u < users.length; u++) {
            if (users[u].id === currentUser.id) {
                users[u].hand.splice(cardIndexInPlayerHand, 1);
            }
        }
        // Update the noThanks and isHeated state
        const updatedNoThanksTracker = updateNoThanksState(currentUser.id);
        const updatedIsHeated = updateIsHeatedState();
        const updatedStacking = updateStackingState();

        // Remove current user from cardTargetUsers
        const updatedCardTargetUsers = cardTargetUsers.filter(user => user !== currentUser.id);
        updateGameState({
            winner: currentUser.name,
            turn: updatedCardTargetUsers.length === 0 ? getNextPlayerId() : turn,
            isHeated: isHeated,
            cardTargetUsers: updatedCardTargetUsers,
            noThanks: { ...noThanks, active: updatedCardTargetUsers.length > 0, tracker: updatedNoThanksTracker },
            stacking: { ...updatedStacking, snowBallEffect: false, stackCount: 0, drawAmount: 0 }
        });
    }


    const fairPlayDiscardHandler = (playedCard: Card, cardIndexInPlayerHand: number) => {
        for (let u = 0; u < users.length; u++) {
            if (users[u].id === fairPlay.id) {
                // The player discards the chosen card
                playedCardsPile.push(playedCard);
                users[u].hand.splice(cardIndexInPlayerHand, 1);
                // Decrement fairPlay.amount by 1
                setFairPlay(prevState => ({ ...prevState, amount: prevState.amount - 1 }));
                // If fairPlay.amount has reached 0, reset the id and amount
                if (fairPlay.amount - 1 === 0) {
                    setFairPlay(prevState => ({ ...prevState, id: '', amount: 0 }));
                    const updatedIsHeated = updateIsHeatedState();
                    const updatedNoThanksTracker = updateNoThanksState();
                    const updatedStacking = updateStackingState();
                    updateGameState({
                        winner: currentUser.name,
                        turn: getNextPlayerId(),
                        currentColor: fairPlay.color,
                        fairPlay: { ...fairPlay, id: '', amount: 0 },
                        apocalypse: apocalypse,
                        isHeated: updatedIsHeated,
                        noThanks: { ...noThanks, tracker: updatedNoThanksTracker },
                        stacking: updatedStacking
                    });
                }
            }
        }
    };

    // This function handles the discard action during the Apocalypse card event.
    const apocalypseDiscardHandler = (playedCard: Card, cardIndexInPlayerHand: number) => {
        // Helper function to check if all users have either drawn or discarded cards during the apocalypse event.
        const allApocalypseActionsDone = (tracker: { id: string, canDiscard: boolean, actionTaken: boolean }[]) =>
            tracker.every((user: { canDiscard: boolean, actionTaken: boolean }) => user.actionTaken);
        // If apocalypse event is active, and the played card matches the apocalypse color and is one of the apocalypse users
        if (apocalypse.active &&
            (playedCard.color === apocalypse.color || playedCard.name === "No, Thanks") &&
            apocalypse.tracker.some(({ id }) => id === currentUser.id)) {
            // Retrieve the apocalypse tracker data of the current user
            const currentUserApocalypseData = apocalypse.tracker.find(({ id }) => id === currentUser.id);
            // If the current user cannot discard, return from the function without doing anything
            if (currentUserApocalypseData && !currentUserApocalypseData.canDiscard) {
                return;
            }
            playedCardsPile.push(playedCard);
            for (let u = 0; u < users.length; u++) {
                if (users[u].id === currentUser.id) {
                    users[u].hand.splice(cardIndexInPlayerHand, 1);
                }
            }
            // Create a copy of the apocalypse state and update the discard property of the current user in the apocalypse tracker.
            const updatedApocalypse = { ...apocalypse };
            const updatedNoThanksTracker = updateNoThanksState();
            const updatedIsHeated = updateIsHeatedState();
            const updatedStacking = updateStackingState();
            const userIndex = updatedApocalypse.tracker.findIndex(({ id }) => id === currentUser.id);
            updatedApocalypse.tracker[userIndex] = {
                ...updatedApocalypse.tracker[userIndex],
                actionTaken: true,
            };
            // Check if all users have either drawn or discarded cards.
            if (allApocalypseActionsDone(updatedApocalypse.tracker)) {
                // If yes, update the game state, set the apocalypse event to inactive,
                // and move the turn to the next player.
                updateGameState({
                    winner: currentUser.name,
                    turn: getNextPlayerId(),
                    currentColor: apocalypse.color,
                    apocalypse: { active: false, color: '', tracker: [] },
                    isHeated: updatedIsHeated,
                    noThanks: { ...noThanks, tracker: updatedNoThanksTracker },
                    stacking: updatedStacking
                });
            } else {
                // If no, just update the apocalypse state and keep the game state unchanged.
                updateGameState({
                    winner: currentUser.name,
                    currentColor: apocalypse.color,
                    apocalypse: updatedApocalypse,
                    isHeated: updatedIsHeated,
                    noThanks: { ...noThanks, tracker: updatedNoThanksTracker },
                    stacking: updatedStacking
                });
            }
        }
    };

    const handleCardReorderingInPlayerHand = (reorderedHand: Card[]) => {
        users[povPlayerIndex].hand = reorderedHand;
    }

    function getCardIndexFromHandById(hand: Card[], id: string) {
        for (let i = 0; i < hand.length; i++) {
            if (hand[i].id === id) {
                return i;
            }
        }
        return -1;
    }

    const handleDragStart = (event: any) => {
        const activeIndex = getCardIndexFromHandById(povPlayerHand, event.active.id);
        if (!povPlayerHand || povPlayerHand[activeIndex] == null) {
            return;
        }
        setDragAndDropOverlayImg(povPlayerHand[activeIndex].image);
    };

    const handleDragEnd = (event: { active: any; over: any; }) => {
        const { active, over } = event;

        if (active == null || over == null) {
            return
        }

        let indexOfCardThatIsBeingDragged: number = -1;
        let cardThatIsBeingDragged: Card = {} as Card;

        for (let c = 0; c < povPlayerHand.length; c++) {
            if (povPlayerHand[c].id === active.id) {
                cardThatIsBeingDragged = povPlayerHand[c];
                indexOfCardThatIsBeingDragged = c;
            }
        }

        // for sorting a card in the povPlayer's hand
        if (active.id !== over.id && over.id.includes("#")) {
            setPovPlayerHand(() => {
                const oldIndex = getCardIndexFromHandById(povPlayerHand, active.id);
                const newIndex = getCardIndexFromHandById(povPlayerHand, over.id);

                const reorderedPlayerHand = arrayMove(povPlayerHand, oldIndex, newIndex)

                users[povPlayerIndex].hand = reorderedPlayerHand;

                return reorderedPlayerHand

            });
        }

        // this is the effect of dragging a card over the center play area
        if (over.id === 'play-area-drop-zone') {
            handleCardClick(cardThatIsBeingDragged, indexOfCardThatIsBeingDragged, null)
            return;
        }

        if (cardEffectIsBeingResolved) {
            console.log("can't drag cards to aother player when another card's effect is being resolved");
            return;
        }

        // checks if I'm trying to offer it to another player using the user id tied to the drop zone it was dropped over
        for (let u = 0; u < users.length; u++) {
            if (users[u].id === over.id) {
                if (users[u].id !== turn) {
                    console.log("You can't offer cards to players unless it's their turn!")
                    return;
                }
                if (hasOfferedCardBeenAcceptedOrRejectedOnThisTurn) {
                    console.log("Only one offered card can be accepted per turn")
                    return;
                }
                socket.emit("offerCard", users[povPlayerIndex].id, cardThatIsBeingDragged.id, offeredCards);
                return;
            }
        }
    }

    const acceptCardOffer = (playerIdOfOneWhoseCardIsBeingAccepted: string) => {

        let cardId = ""
        for (let i = 0; i < offeredCards.length; i++) {
            if (playerIdOfOneWhoseCardIsBeingAccepted === offeredCards[i].playerId) {
                cardId = offeredCards[i].cardId
            }
        }

        for (let u = 0; u < users.length; u++) {
            // find whether the cardId is in a specific users hand
            if (users[u].hand.filter(card => card.id === cardId).length !== 0) {
                // reubuild that users hand, without the card given away
                const givingUserHand: Card[] = [];
                for (let c = 0; c < users[u].hand.length; c++) {
                    if (users[u].hand[c].id !== cardId) {
                        givingUserHand.push(users[u].hand[c])
                    }
                    else {
                        // give the card to the player whose turn it is
                        users[povPlayerIndex].hand.push(users[u].hand[c]);
                        setPovPlayerHand(users[povPlayerIndex].hand);
                    }
                }
                users[u].hand = givingUserHand;
                break;
            }
        }
        socket.emit("offeredCardHasBeenAccepted")
        updateGameState({
            users: users
        });


    }

    const delay = (ms: number) => new Promise(
        resolve => setTimeout(resolve, ms)
    );

    // console.log("user", currentUser);
    const onCardPlayedHandler = async (playedCard: Card, cardIndexInPlayerHand: number, playedCardImageRef?: any) => {
        // console.log("Users", users);
        if (isThisCardAllowedToBePlayed(playedCard)) {
            // animate card going to discard pile
            if (playedCardImageRef != null && playedCardImageRef.current != null) {
                playedCardImageRef.current.style.transform = `translateY(${topOfDiscardPileRef.current?.y - playedCardImageRef.current.y}px)`;
                playedCardImageRef.current.style.transform += `translateX(${topOfDiscardPileRef.current?.x - playedCardImageRef.current.x}px)`;
                // put in a delay so there's enough time for the 500ms animation of the card moving to finish
                await delay(400);
            }
            // add card to discard pile
            playedCardsPile.push(playedCard)
            for (let u = 0; u < users.length; u++) {
                if (users[u].id === currentUser.id) {
                    users[u].hand.splice(cardIndexInPlayerHand, 1);
                }
            }
            const updatedNoThanksTracker = updateNoThanksState();
            const updatedIsHeated = updateIsHeatedState();
            const updatedStacking = handleSnowBallUpdate(playedCard.name);
            updateGameState({
                winner: currentUser.name,
                isHeated: updatedIsHeated,
                noThanks: { ...noThanks, tracker: updatedNoThanksTracker },
                stacking: updatedStacking
            });
            if (playedCard.color === "Black" && playedCard.name === "Getting Heated") {
                setModalType("gettingHeated");
            }
            else if (playedCard.color === "Black" && playedCard.name === "Ghost Pepper Spray") {
                setModalType("ghostPepper");
            }
            else if (playedCard.color === "Black" && playedCard.name === "Fair Play") {
                setModalType("fairPlay");
            }
            else if (playedCard.color === "Black" && playedCard.name === "Apocalypse") {
                setModalType("apocalypse");
            }
            else if (playedCard.color === "Black") {
                setModalType("colorPicker");
                // on modal close, we update game state in onHideModal()
            }
            else if (playedCard.type === "Action" && playedCard.name === "Swap") {
                setModalType("swap");
            }
            else if (playedCard.type === "Action" && playedCard.name === "Too Hot") {
                setSlapState(true);
                updateGameState({
                    winner: currentUser.name,
                    turn: currentUser.id,
                    currentColor: playedCard.color,
                    slapState: true,
                    isHeated: updatedIsHeated,
                    noThanks: { ...noThanks, tracker: updatedNoThanksTracker },
                    stacking: updatedStacking
                })
            }
            else if (playedCard.type === "Action" && playedCard.name === "Draw 2") {
                // handle draw 2 functionality
                setTimeout(() => {
                    // Sets timeout delay to allow react state updates time to catch up to Draw 2 execution
                    let nextPlayerId = getNextPlayerId();
                    setCardTargetUsers([nextPlayerId]);
                    if (updatedStacking.active || updatedStacking.snowBallEffect) {
                        snowBallDelayEffect(nextPlayerId, playedCard.color, updatedStacking, (userId: string) => {
                            nextPlayerId = getNextPlayerId();
                            makePlayerDrawCards(nextPlayerId, updatedStacking.drawAmount)
                            updateGameState({
                                winner: currentUser.name,
                                turn: nextPlayerId,
                                currentColor: playedCard.color,
                                cardTargetUsers: [nextPlayerId],
                                isHeated: updatedIsHeated,
                                noThanks: { ...noThanks, active: false },
                                stacking: { ...updatedStacking, snowBallEffect: false, stackCount: 0, drawAmount: 0 }
                            });
                        })
                    } else {
                        noThanksBlockEffect([nextPlayerId], playedCard.color, (userId: string) => {
                            if (userId === nextPlayerId) {
                                makePlayerDrawCards(nextPlayerId, updatedStacking.drawAmount);
                                updateGameState({
                                    winner: currentUser.name,
                                    turn: getNextPlayerId(),
                                    currentColor: playedCard.color,
                                    isHeated: updatedIsHeated,
                                    cardTargetUsers: [nextPlayerId],
                                    noThanks: { ...noThanks, tracker: updatedNoThanksTracker },
                                    stacking: updatedStacking
                                })
                            }
                        })
                    }
                }, 100);
            }
            else if (playedCard.type === "Action" && playedCard.name === "Reverse") {
                // handle reverse functionality
                const isReversing = true;
                if (orderOfPlay === "standard") {
                    updateGameState({
                        winner: currentUser.name,
                        turn: getNextPlayerId(isReversing),
                        currentColor: playedCard.color,
                        orderOfPlay: "reverse",
                        isHeated: updatedIsHeated,
                        noThanks: { ...noThanks, tracker: updatedNoThanksTracker },
                        stacking: updatedStacking
                    })
                    setOrderOfPlay("reverse")
                } else {
                    updateGameState({
                        winner: currentUser.name,
                        turn: getNextPlayerId(isReversing),
                        currentColor: playedCard.color,
                        orderOfPlay: "standard",
                        isHeated: updatedIsHeated,
                        noThanks: { ...noThanks, tracker: updatedNoThanksTracker },
                        stacking: updatedStacking
                    })
                    setOrderOfPlay("standard")
                }
            }
            else if (playedCard.type === "Action" && playedCard.name === "Cool Off") {
                // handle cool off functionality
                updateGameState({
                    winner: currentUser.name,
                    turn: skipNextPlayerId(),
                    currentColor: playedCard.color,
                    isHeated: updatedIsHeated,
                    noThanks: { ...noThanks, tracker: updatedNoThanksTracker },
                    stacking: updatedStacking
                })
            }
            else {
                // we have a specific check not to set color to 'White'
                updateGameState({
                    winner: currentUser.name,
                    turn: getNextPlayerId(),
                    currentColor: playedCard.color !== 'White' ? playedCard.color : currentColor,
                    isHeated: updatedIsHeated,
                    noThanks: { ...noThanks, tracker: updatedNoThanksTracker },
                    stacking: updatedStacking
                })
            }
        }
        else {
            console.log("Can't play that")
        }
    }

    const onCardDrawnHandler = async () => {
        const drawnCard = drawCardPile.pop()
        const cardsInPlayerHand = document.getElementsByClassName('player-heated-card');
        const lastCardInPlayerHand: any = cardsInPlayerHand[cardsInPlayerHand.length - 1]
        const userCantDraw = users.find(user => user.id === currentUser.id)?.hand.some(card => isThisCardAllowedToBePlayed(card));
        // Comment this out to allow infinite drawing
        if (userCantDraw) {
            return;
        }
        // animate card going to end of player hand
        if (lastCardInPlayerHand && lastCardInPlayerHand.x != null && lastCardInPlayerHand.y != null) {
            drawnCardRef.current.src = drawnCard?.image;
            drawnCardRef.current.style.display = 'block';
            drawnCardRef.current.style.transform = `translateY(${lastCardInPlayerHand.y - drawnCardRef.current?.y}px)`;
            drawnCardRef.current.style.transform += `translateX(${lastCardInPlayerHand.x - drawnCardRef.current?.x + 35}px)`;
            // put in a delay so there's enough time for the 300ms animation of the card moving to finish
            await delay(300);
            drawnCardRef.current.style.display = 'none';
            drawnCardRef.current.style.transform = 'none';
            drawnCardRef.current.src = cardBackImg;
        }
        for (let u = 0; u < users.length; u++) {
            if (users[u].id === currentUser.id) {
                users[u].hand.push(drawnCard!);
            }
        }
        const updatedNoThanksTracker = updateNoThanksState();
        const updatedIsHeated = updateIsHeatedState();
        const updatedStacking = updateStackingState();
        updateGameState({
            winner: currentUser.name,
            turn: currentUser.id,
            isHeated: updatedIsHeated,
            noThanks: { ...noThanks, tracker: updatedNoThanksTracker },
            stacking: updatedStacking
        });
    }

    // This function handles the draw action during the Apocalypse card event.
    // It draws a card for the current user, updates the apocalypse tracker, and checks
    // if all users have either drawn or discarded cards.
    const apocalypseDrawHandler = () => {
        // Helper function to check if all users have either drawn or discarded cards during the apocalypse event.
        const allApocalypseActionsDone = (tracker: { id: string, canDiscard: boolean, actionTaken: boolean }[]) =>
            tracker.every((user: { canDiscard: boolean, actionTaken: boolean }) => user.actionTaken);
        const apocalypseUser = apocalypse.tracker.find(({ id }) => id === currentUser.id);
        // If the current user is part of the apocalypse event and their canDiscard property is false then they can perform a draw operation, otherwise the function returns nothing.
        if (apocalypseUser && !apocalypseUser.canDiscard) {
            const drawnCard = drawCardPile.pop()
            for (let u = 0; u < users.length; u++) {
                if (users[u].id === currentUser.id) {
                    users[u].hand.push(drawnCard!);
                }
            }
        } else {
            return;
        }
        // If apocalypse event is active, create a copy of the apocalypse state
        // and update the draw property of the current user in the apocalypse tracker.
        let updatedApocalypse = apocalypse;
        const updatedNoThanksTracker = updateNoThanksState();
        const updatedIsHeated = updateIsHeatedState();
        const updatedStacking = updateStackingState();
        if (apocalypse.active) {
            const updatedTracker = apocalypse.tracker.map((item) => {
                if (item.id === currentUser.id) {
                    return { ...item, actionTaken: true };
                }
                return item;
            });
            updatedApocalypse = {
                ...apocalypse,
                tracker: updatedTracker,
            };
        }
        // Check if all users have either drawn or discarded cards.
        if (allApocalypseActionsDone(updatedApocalypse.tracker)) {
            // If yes, update the game state, set the apocalypse event to inactive,
            // and move the turn to the next player.
            updateGameState({
                winner: currentUser.name,
                turn: getNextPlayerId(),
                apocalypse: { active: false, color: '', tracker: [] },
                isHeated: updatedIsHeated,
                noThanks: { ...noThanks, tracker: updatedNoThanksTracker },
                stacking: updatedStacking
            });
        } else {
            // If no, just update the apocalypse state and keep the game state unchanged.
            updateGameState({
                winner: currentUser.name,
                apocalypse: updatedApocalypse,
                isHeated: updatedIsHeated,
                noThanks: { ...noThanks, tracker: updatedNoThanksTracker },
                stacking: updatedStacking
            });
        }
    }

    const handleSayHeated = () => {
        // Find the index of the current user in the isHeated.tracker
        let trackerIndex = isHeated.tracker.findIndex(user => user.id === currentUser.id && !user.saidHeated);
        // If the currentUser is found in the tracker with saidHeated set to false
        if (trackerIndex !== -1) {
            // Update the saidHeated property to true for the current user
            setIsHeated(prevState => {
                let updatedTracker = [...prevState.tracker];
                updatedTracker[trackerIndex].saidHeated = true;
                let newHeatedState = { ...prevState, active: false, tracker: updatedTracker };
                updateGameState({
                    winner: currentUser.name,
                    isHeated: newHeatedState,
                });
                return newHeatedState;
            });
        } else {
            // If currentUser is not found in the tracker or has already saidHeated
            // Find the index of the user in the tracker who has not saidHeated
            let otherPlayerIndex = isHeated.tracker.findIndex(user => !user.saidHeated);
            if (otherPlayerIndex !== -1) {
                // Make this player draw 2 cards
                makePlayerDrawCards(isHeated.tracker[otherPlayerIndex].id, 2);
                // Remove this player from the tracker and disable the active heated state
                setIsHeated(prevState => {
                    let updatedTracker = [...prevState.tracker];
                    updatedTracker.splice(otherPlayerIndex, 1);
                    let newHeatedState = { ...prevState, active: false, tracker: updatedTracker };
                    updateGameState({
                        winner: currentUser.name,
                        isHeated: newHeatedState,
                    });
                    return newHeatedState;
                });
            }
        }
    }

    const handleSlap = () => {
        // Emit a 'slap' event to the server with the current user's ID
        socket.emit('slap', currentUser.id);
        // Add the current user's ID to the slapTracker array
        setSlapTracker(prevSlapTracker => [...prevSlapTracker, { id: currentUser.id }]);
        // Check if all players except one user (presumed loser) have slapped
        if (slapTracker.length === users.length - 1) {
            // Find the player who did not slap (the slap loser)
            const slapLoser = users.find(user => !slapTracker.some(slap => slap.id === user.id));
            if (slapLoser) {
                setCardTargetUsers([slapLoser.id]);
                makePlayerDrawCards(slapLoser.id, 2);
                // Reset the slapTracker array
                setSlapTracker([]);
                setSlapState(false);
                const updatedNoThanksTracker = updateNoThanksState();
                const updatedIsHeated = updateIsHeatedState();
                const updatedStacking = updateStackingState();
                updateGameState({
                    winner: currentUser.name,
                    turn: getNextPlayerId(),
                    slapState: false,
                    slapTracker: [],
                    isHeated: updatedIsHeated,
                    cardTargetUsers: [slapLoser.id],
                    noThanks: { ...noThanks, tracker: updatedNoThanksTracker },
                    stacking: updatedStacking
                });
                // Emit an event to the server to update the slapTracker for all clients
                socket.emit('slapTrackerUpdate', slapTracker);
                // Emit an event to the server to update the slapTracker for all clients
                socket.emit('slapStateUpdate', slapState);
            }
        }
    }

    return (
        <div className={`HeatedGame background-color-${currentColor}`}>
            {(!roomFull) ? <>
                <>
                    {modalType && <CardActionModal
                        users={users}
                        currentUser={currentUser}
                        show={true}
                        onClose={modalCloseHandlers[modalType] || (() => setModalType(null))}
                        type={modalType} />}
                </>
                <div className='topInfo'>
                    <img src={require('../assets/logo.png').default} />
                    <TopInformationBar gameCode={room} idOfPlayerWhoseTurnItIs={turn} currentColor={currentColor} users={users} />
                    <span>
                        <button className='game-button green' onClick={() => setSoundMuted(!isSoundMuted)}>{isSoundMuted ? <span className="material-icons">volume_off</span> : <span className="material-icons">volume_up</span>}</button>
                        {!isMusicMuted ? <MusicNoteOutlinedIcon style={{ "cursor": "pointer" }} onClick={() => { setMusicMuted(!isMusicMuted) }} /> : <MusicOffOutlinedIcon style={{ "cursor": "pointer" }} onClick={() => { setMusicMuted(!isMusicMuted) }} />}
                    </span>
                </div>

                {/* PLAYER LEFT MESSAGES */}
                {/* TODO the room notifications should exist like in the bottom right corner, updating when someone joins, leaves, or game begins */}
                {users.length === 1 && <h1 className='topInfoText'>Waiting for more players to join the game.</h1>}

                {users.length >= 2 && <>

                    {gameOver ? <div>{winner !== '' && <><h1>GAME OVER</h1><h2>{winner} wins!</h2></>}</div> :
                        <DndContext
                            sensors={sensors}
                            collisionDetection={pointerWithin}
                            onDragEnd={handleDragEnd}
                            onDragStart={handleDragStart}
                        >
                            <div className='play-area-and-players'>
                                <div className='other-player-hands-container'>
                                    <div className='play-area-top-row'>
                                        <OtherPlayerHand userCount={users.length} currentPositionOfDiv='top-right' currentUserId={currentUser.id}
                                            idForWhoseTurnItIs={turn} users={users} offeredCards={offeredCards} acceptCardOffer={acceptCardOffer} />
                                    </div>

                                    <div className='play-area-left-column'>
                                        <OtherPlayerHand userCount={users.length} currentPositionOfDiv='left-top' currentUserId={currentUser.id}
                                            idForWhoseTurnItIs={turn} users={users} offeredCards={offeredCards} acceptCardOffer={acceptCardOffer} />
                                    </div>
                                    <div className='play-area-right-column'>
                                        <OtherPlayerHand userCount={users.length} currentPositionOfDiv='right-bottom' currentUserId={currentUser.id}
                                            idForWhoseTurnItIs={turn} users={users} offeredCards={offeredCards} acceptCardOffer={acceptCardOffer} />
                                        <OtherPlayerHand userCount={users.length} currentPositionOfDiv='right-center' currentUserId={currentUser.id}
                                            idForWhoseTurnItIs={turn} users={users} offeredCards={offeredCards} acceptCardOffer={acceptCardOffer} />
                                        <OtherPlayerHand userCount={users.length} currentPositionOfDiv='right-top' currentUserId={currentUser.id}
                                            idForWhoseTurnItIs={turn} users={users} offeredCards={offeredCards} acceptCardOffer={acceptCardOffer} />
                                    </div>
                                </div>
                                <div className='center-row-play-area'>
                                    <DropZone id="play-area-drop-zone">
                                        <div className={`play-area play-area-${currentColor}`} style={getPlayAreaStyle()}>
                                            {
                                                playedCardsPile.length > 0 &&
                                                <img
                                                    className='top-discarded-heated-card'
                                                    src={playedCardsPile[playedCardsPile.length - 1].image}
                                                    ref={topOfDiscardPileRef}
                                                />
                                            }
                                            <div className='draw-card-pile-container' style={{ "cursor": !(apocalypse.active || turn === currentUser.id || slapState) ? "initial" : "pointer" }}>
                                                <div className='draw-card-pile-images' onClick={handleDrawCardForFacedownDeck}>
                                                    <img src={cardBackImg} className='draw-card-pile-img' />
                                                    <img src={cardBackImg} className='draw-card-pile-img img-2' />
                                                    <img src={cardBackImg} className='draw-card-pile-img img-3' />
                                                    <img src={cardBackImg} className='draw-card-pile-img drawn-card' ref={drawnCardRef} />
                                                </div>
                                                <div className='draw-card-pile-text'>
                                                    <p>Click deck to draw</p>
                                                </div>

                                            </div>
                                            <button
                                                className={`slap-button ${slapState ? 'active' : ''}`}
                                                {...getSlapButtonStyle()}
                                                onClick={handleSlap}>Slap!
                                            </button>
                                            <button
                                                className={`heated-button `}
                                                {...getHeatedButtonStyle()}
                                                onClick={handleSayHeated}>HEATED!
                                            </button>
                                        </div>
                                    </DropZone>

                                </div>

                                <CurrentPlayerHand renderPlayerText={renderPlayerText} handOfCurrentPlayer={povPlayerHand} handleCardClick={handleCardClick} name={currentUser.name} handleCardReorderingInPlayerHand={handleCardReorderingInPlayerHand} offeredCards={offeredCards} />
                                {/* Drag overlay is the thing that is being dragged around when outside of the sortable container */}
                                <DragOverlay dropAnimation={null}>
                                    <img
                                        className='player-heated-card-overlay'
                                        style={{ width: "7rem" }}
                                        src={dragAndDropOverlayImg}
                                    />
                                </DragOverlay>
                            </div>
                        </DndContext>
                    }
                </>}
            </>
                : <div><h1>Room full</h1></div>
            }

            {users.length <= 1 && <>
                <br />
                <a href='/'><button className="game-button red">QUIT</button></a>
            </>}

        </div >
    )
}

export default HeatedGame
