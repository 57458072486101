import "./css/otherPlayerHand.css";
import { Card } from '../utils/cards';
import cardBackImg from '../assets/card-back.png'
import { User } from "./HeatedGame";
import { useEffect, useState } from "react";
import StarsIcon from '@mui/icons-material/Stars';
import { Icon, Zoom, styled } from "@mui/material";
import { DropZone } from "./DropZone";
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import React from "react";

const CardIsBeingOfferedTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: 'rgba(137, 203, 236, .5)',
        color: 'rgba(0, 0, 0, 1)',
        maxWidth: 300,
        fontWeight: 600,
        fontSize: theme.typography.pxToRem(14),
        border: '1px solid gray',
        margin: 0,
        // this long style does the white outline of the text
        textShadow: '0px 1px 2px #FFF,1px 0px 2px #FFF,0px -1px 2px #FFF,-1px -0px 2px #FFF,1px 1px 2px #FFF, 0px 1px 2px #FFF,1px 0px 2px #FFF,0px -1px 2px #FFF,-1px -0px 2px #FFF,1px 1px 2px #FFF, 0px 1px 2px #FFF,1px 0px 2px #FFF,0px -1px 2px #FFF,-1px -0px 2px #FFF,1px 1px 2px #FFF, 0px 1px 2px #FFF,1px 0px 2px #FFF,0px -1px 2px #FFF,-1px -0px 2px #FFF,1px 1px 2px #FFF'
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: 'rgba(137, 203, 236, .5)',
    },
}));

type OtherPlayerHandProps = {
    users: User[];
    userCount: number;
    currentPositionOfDiv: string;
    currentUserId: string;
    idForWhoseTurnItIs: string;
    offeredCards: { playerId: string, cardId: string }[];
    acceptCardOffer: (playerIdOfOneWhoseCardIsBeingAccepted: string) => void;
    // rescindOffer: () => void;
}

function OtherPlayerHand(props: OtherPlayerHandProps) {

    const [displayState, setDisplayState] = useState("none");
    const [userIndexToDisplay, setUserIndexToDisplay] = useState<number>(0);

    const userIndexInUsersArray = props.users.indexOf(props.users.filter(u => u.id === props.currentUserId)[0])
    // adjust the array so the board looks the same for every player
    const usersArrayFromCurrentUserPerspective: User[] = props.users.slice(userIndexInUsersArray + 1).concat(props.users.slice(0, userIndexInUsersArray));

    useEffect(() => {
        // this determines whether the div exists to display and deal with spacing all at once
        if (props.currentPositionOfDiv === "top-right") {
            setDisplayState("block");
            if (props.userCount === 2) {
                setUserIndexToDisplay(1); // Player 2
            }
            else if (props.userCount >= 3 && props.userCount < 5) {
                setUserIndexToDisplay(2); // Player 3
            }
            else {
                setUserIndexToDisplay(3); // Player 4
            }
        }
        if (props.currentPositionOfDiv === "right-bottom") {
            if (props.userCount >= 3) {
                setDisplayState("block");
                setUserIndexToDisplay(1); // Player 2
            }
        }
        if (props.currentPositionOfDiv === "left-top") {
            if (props.userCount == 4) {
                setDisplayState("block");
                setUserIndexToDisplay(3); // Player 4
            }
            if (props.userCount >= 5) {
                setDisplayState("block");
                setUserIndexToDisplay(4); // Player 5
            }
        }
        // anything below here is to support 5 or more players
        if (props.currentPositionOfDiv === "right-center") {
            if (props.userCount >= 5) {
                setUserIndexToDisplay(2); // Player 3
                setDisplayState("block");
            }

        }
    }, [props.userCount])

    const renderPlayerHandBasedOnNumberOfCardsInHand = (hand: Card[]) => {
        if (hand.length <= 10) {
            return usersArrayFromCurrentUserPerspective[userIndexToDisplay - 1].hand.map((_: Card, i: number) => (
                <img
                    key={usersArrayFromCurrentUserPerspective[userIndexToDisplay - 1].name + i.toString()}
                    className='heated-card'
                    src={cardBackImg}
                />
            ))
        }
        return <div className="other-player-hand-name-card-count-container"> <img
            className='heated-card'
            src={cardBackImg}
        /> <p className="other-player-hand-name-card-count-text">x {hand.length}</p></div>
    }

    const thisPlayerIsOfferingACard = () => {
        for (let i = 0; i < props.offeredCards.length; i++) {
            if (props.offeredCards[i].playerId === usersArrayFromCurrentUserPerspective[userIndexToDisplay - 1].id) {
                return true;
            }
        }
        return false;
    }

    const handleOtherPlayerAreaClick = () => {
        if (!thisPlayerIsOfferingACard()) {
            return;
        }
        // can only take a card if it's your turn
        if (props.currentUserId === props.idForWhoseTurnItIs) {
            props.acceptCardOffer(usersArrayFromCurrentUserPerspective[userIndexToDisplay - 1].id);
        }
    }

    const getTooltipPlacement = () => {
        if (props.currentPositionOfDiv.includes("top-")) {
            return "bottom"
        }
        else if (props.currentPositionOfDiv.includes("right-")) {
            return "left"
        }
        else if (props.currentPositionOfDiv.includes("left-")) {
            return "right"
        }
    }

    const renderTooltipText = () => {
        let toolTipMessage = "";
        toolTipMessage += usersArrayFromCurrentUserPerspective[userIndexToDisplay - 1].name + " is offering ";
        if (props.currentUserId === props.idForWhoseTurnItIs) {
            toolTipMessage += "you"
        }
        else {
            for (let i = 0; i < props.users.length; i++) {
                if (props.users[i].id === props.idForWhoseTurnItIs) {
                    toolTipMessage += props.users[i].name
                }
            }
        }
        toolTipMessage += " a card.";
        if (props.currentUserId === props.idForWhoseTurnItIs) {
            toolTipMessage += " Click their hand to accept."
        }
        return <React.Fragment>
            <span>{toolTipMessage}</span>
        </React.Fragment>
    }

    return (
        <> {usersArrayFromCurrentUserPerspective && usersArrayFromCurrentUserPerspective[userIndexToDisplay - 1] ?

            <DropZone id={`${usersArrayFromCurrentUserPerspective[userIndexToDisplay - 1].id}`}>
                <CardIsBeingOfferedTooltip
                    TransitionComponent={Zoom}
                    title={renderTooltipText()}
                    placement={getTooltipPlacement()}
                    open={thisPlayerIsOfferingACard()}
                    sx={{ fontSize: "18px" }}
                    arrow >
                    <div className={`other-player-hand-container ${props.currentPositionOfDiv} ${thisPlayerIsOfferingACard() ? "player-is-offering-card" : ""}`}
                        style={{ display: displayState }}
                        onClick={() => {
                            handleOtherPlayerAreaClick()
                        }}>
                        <div className="other-player-hand-name-container">
                            <p className='other-player-hand-name'>{usersArrayFromCurrentUserPerspective[userIndexToDisplay - 1].name}</p>
                            {props.idForWhoseTurnItIs === usersArrayFromCurrentUserPerspective[userIndexToDisplay - 1].id && <Icon component={StarsIcon} className="other-player-hand-name-turn-icon" />}
                        </div>
                        {renderPlayerHandBasedOnNumberOfCardsInHand(usersArrayFromCurrentUserPerspective[userIndexToDisplay - 1].hand)}
                    </div>
                </CardIsBeingOfferedTooltip>
            </DropZone >


            : <div style={{ display: displayState }}>Loading...</div>
        }

        </>
    )

}

export default OtherPlayerHand;


