import React, { useRef, useState } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Card } from '../utils/cards';
import InfoIcon from '@mui/icons-material/Info';
import Icon from '@mui/material/Icon';
import Tooltip from '@mui/material/Tooltip';

export function SortableCard(props: {
    id: string, card: Card, index: number, handleCardClick: (card: Card, index: number, cardImageRef: any) => void, offeredCards: {
        playerId: string;
        cardId: string;
    }[]
},) {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        isDragging
    } = useSortable({ id: props.id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        opacity: isDragging ? 0.5 : 1,
    };

    let cardImgRef = useRef(null);
    const [showInfoIcon, setShowInfoIcon] = useState<boolean>(false);
    const [isCardBeingHoveredOver, setIsCardBeingHoveredOver] = useState(false);

    const renderInfoToolTipInformation = () => {
        let cardName = "";
        let cardDescription = "";

        if (props.card.type === "Number") {
            cardName = props.card.name;
        }
        else {
            if (props.card.color !== "White" && props.card.color !== "Black") {
                cardName += props.card.color + " "
            }

            cardName += props.card.name;
        }

        if (props.card.description != null) {
            cardDescription = props.card.description;
            if (props.card.color !== "White" && props.card.color !== "Black") {
                cardDescription += " " + cardName + " can be played on any color of " + props.card.name + " or any other " + props.card.color + " card."
            }
            else {
                cardDescription += " " + cardName + " can be played on any other card on your turn."
            }
        }
        else {
            cardDescription += cardName + " can be played on any color of " + props.card.number + " or any other " + props.card.color + " card."
        }

        return <React.Fragment>
            <h4>{cardName}</h4>
            <p style={{ "fontSize": "14px" }}>{cardDescription}</p>
        </React.Fragment>
    }

    const renderOfferedTooltipInformation = () => {
        let cardName = "";

        if (props.card.type === "Number") {
            cardName = props.card.name;
        }
        else {
            if (props.card.color !== "White" && props.card.color !== "Black") {
                cardName += props.card.color + " "
            }

            cardName += props.card.name;
        }

        return <React.Fragment>
            <p style={{ "fontSize": "18px", margin: "none" }}>You have offered this {cardName} to the current player</p>
        </React.Fragment>
    }

    const hasCardBeenOffered = () => {
        for (let i = 0; i < props.offeredCards.length; i++) {
            if (props.offeredCards[i].cardId === props.card.id) {
                return true;
            }
        }
        return false
    }

    const onMouseHasEnteredCardContainer = () => {
        setShowInfoIcon(true);
        setIsCardBeingHoveredOver(true);
    }

    const onMouseHasLeftCardContainer = () => {
        setShowInfoIcon(false);
        setIsCardBeingHoveredOver(false);
    }

    return (
        <div ref={setNodeRef} style={style} {...attributes} {...listeners} className='sortable-card-container' onMouseEnter={() => { onMouseHasEnteredCardContainer() }
        } onMouseLeave={() => onMouseHasLeftCardContainer()}>
            <img
                ref={cardImgRef}
                key={props.id}
                className={`player-heated-card ${hasCardBeenOffered() ? "this-card-has-been-offered" : ""}`}
                onClick={() => { setShowInfoIcon(false); props.handleCardClick(props.card, props.index, cardImgRef) }}
                src={props.card.image}
            />
            {hasCardBeenOffered() &&
                <Tooltip title={renderOfferedTooltipInformation()} placement='top'>
                    <p className='player-heated-card-offered-text' style={{
                        zIndex: isCardBeingHoveredOver ? "1" : "0"
                    }}>
                        OFFERED
                    </p>
                </Tooltip>

            }
            <Tooltip style={{ "display": showInfoIcon ? "block" : "none" }} title={
                renderInfoToolTipInformation()
            } placement='top' leaveDelay={0} PopperProps={{
                modifiers: [
                    {
                        name: "offset",
                        options: {
                            offset: [35, 125], // [x-offset, y-offset]
                        },
                    },
                ],
            }}>
                <Icon className='player-hand-card-info-icon' component={InfoIcon} style={{ "visibility": showInfoIcon ? "initial" : "hidden" }} onClick={() => { props.handleCardClick(props.card, props.index, cardImgRef) }} />
            </Tooltip>
        </div >
    );
}