import { FC, ReactNode } from 'react';
import { useDroppable, UniqueIdentifier } from '@dnd-kit/core';

export const DropZone: FC<{ children: ReactNode, id: UniqueIdentifier }> = ({ children, id }) => {
    const { setNodeRef, isOver } = useDroppable({ id })

    // sets the color to be gray when hovering over. Should utilize it to show something like a tooltip when hovering over the dropzone of another player
    const style = {
        backgroundColor: isOver ? 'grey' : 'inherit',
    };

    return (
        <div ref={setNodeRef} style={style}>
            {children}
        </div>
    );
}