import { useEffect, useState } from "react";
import { User } from "./HeatedGame";
import "./css/topInformationBar.css";

type TopInformationBarProps = {
    gameCode: string | string[] | null;
    idOfPlayerWhoseTurnItIs: string;
    currentColor: string;
    users: User[];
}

export function TopInformationBar(props: TopInformationBarProps) {

    const [currentPlayerName, setCurrentPlayerName] = useState("")

    useEffect(() => {
        for (let u = 0; u < props.users.length; u++) {
            if (props.users[u].id === props.idOfPlayerWhoseTurnItIs) {
                setCurrentPlayerName(props.users[u].name)
            }
        }

    }, [props.idOfPlayerWhoseTurnItIs])

    return (
        <div className="top-information-bar">
            <div className="top-information-player-turn" style={{ display: currentPlayerName !== "" ? "block" : "none" }}>
                <p>
                    Turn: {currentPlayerName}
                </p>
            </div>
            <div className="top-information-current-color" style={{ display: currentPlayerName !== "" ? "block" : "none" }}>
                <p>
                    Color: {props.currentColor}
                </p>
            </div>
            <div className="top-information-game-code" style={{ "marginLeft": currentPlayerName !== "" ? "15px" : "0" }}>
                <p>
                    Game Code: {props.gameCode}
                </p>
            </div>
        </div>
    );
}

function setState(arg0: string): [any, any] {
    throw new Error("Function not implemented.");
}
