import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import "./css/cardActionModal.css";
import { User } from "./HeatedGame";
import { COLOR_BLUE, COLOR_GREEN, COLOR_PURPLE, COLOR_YELLOW } from '../utils/colors';

interface CardActionModalProps {
  users: User[];
  currentUser: User;
  show: boolean;
  onClose: Function;
  type: "ghostPepper" | "fairPlay" | "gettingHeated" | "apocalypse" | "colorPicker" | "swap";
}

const CardActionModal: React.FC<CardActionModalProps> = ({ users, currentUser, show, onClose, type }) => {

  const drawOptions = (): any => {
    let emptyDrawSelections: { [playerId: string]: number } = {};
    for (let i = 0; i < users.length; i++) {
      if (users[i].id !== currentUser.id) {
        emptyDrawSelections[users[i].id] = 0
      }
    }
    return emptyDrawSelections;
  }

  const [selectedPlayer, setSelectedPlayer] = useState('');
  const [selectedId, setSelectedId] = useState('');
  const [selectedColor, setSelectedColor] = useState('');
  const [selectedPlayerButton, setSelectedPlayerButton] = useState(-1);
  const [userDrawSelections, setUserDrawSelections] = useState<{ [playerId: string]: number }>(drawOptions());
  const [nextDrawCountOption, setNextDrawCountOption] = useState(1);

  const renderModal = () => {
    if (type === "ghostPepper") {
      return ghostPepperModal();
    } else if (type === "fairPlay") {
      return fairPlayModal();
    } else if (type === "gettingHeated") {
      return gettingHeatedModal();
    } else if (type === "apocalypse") {
      return apocalypseModal();
    } else if (type === "colorPicker") {
      return colorPickerModal();
    } else if (type === "swap") {
      return swapModal();
    }
  };

  const onSet = () => {
    if (type === "apocalypse" || type === "colorPicker") {
      onSetColor();
    } else if (type === "ghostPepper") {
      onSetGhostPepper();
    } else if (type === "fairPlay") {
      onSetFairPlay();
    } else if (type === "gettingHeated") {
      onSetGettingHeated();
    } else if (type === "swap"){
      onSetSwap()
    }
  }

  const onSetColor = () => {
    onClose(selectedColor);
  };

  const onSetGhostPepper = () => {
    onClose(userDrawSelections, selectedColor);
  }

  const onSetGettingHeated = () => {
    onClose(selectedId, selectedColor);
  }

  const onSetSwap = () => {
    onClose(selectedId);
  }

  const onSetFairPlay = () => {
    // Finding the selected user based on the selectedId
    const selectedUser: User | undefined = users.find((user) => user.id === selectedId);
    // Finding the current user
    const currentPlayer: User | undefined = users.find((user) => user.id === currentUser.id);
    if (selectedUser && currentPlayer) {
      // Get the hand size for the current user and the selected user
      const currentUserHandLength: number = currentPlayer.hand.length;
      const selectedUserHandLength: number = selectedUser.hand.length;
      let updatedSelectedId: string = selectedId;
      let updatedDiscardAmount: number = 0;
      if (selectedUserHandLength > currentUserHandLength) {
        updatedSelectedId = selectedUser.id;
        updatedDiscardAmount = selectedUserHandLength - currentUserHandLength;
      } else if (selectedUserHandLength < currentUserHandLength) {
        updatedSelectedId = currentPlayer.id;
        updatedDiscardAmount = currentUserHandLength - selectedUserHandLength;
      } else {
        // If both players have the same amount of cards, reset updatedSelectedId and updatedDiscardAmount
        updatedSelectedId = '';
        updatedDiscardAmount = 0;
      }
      onClose(updatedSelectedId, selectedColor, updatedDiscardAmount);
    }
  };

  // determines what the next number to give should be. For example, if someone selects 1,2,3,4 and then removes 3, the next number
  // to be offered will be 3
  const determineNextDrawCount = (userDrawSelectionsCopy: { [playerId: string]: number | undefined }) => {
    // we start at 1 and increment up until we run out of players
    let nextNumber = 1;
    while (nextNumber < users.length + 1) {
      const userDrawSelectionsKeys = Object.keys(userDrawSelectionsCopy);
      let foundNextNumberInDrawSelectionsAlready = false;
      // we search the already established selections to see if the number we are looking for has already been set
      for (let i = 0; i < userDrawSelectionsKeys.length; i++) {
        if (userDrawSelectionsCopy[userDrawSelectionsKeys[i]] === nextNumber) {
          foundNextNumberInDrawSelectionsAlready = true
        }
      }
      // if it hasn't been set to any id yet, then we return it and we're done
      if (!foundNextNumberInDrawSelectionsAlready) {
        setNextDrawCountOption(nextNumber);
        return;
      }
      // if this number has already been set, we increment and keep searching
      nextNumber += 1;
    }
  }

  const toggleSelectionForId = (playerId: string) => {
    const userDrawSelectionsCopy: { [playerId: string]: number } = {}
    const userDrawSelectionsKeys = Object.keys(userDrawSelections);
    // this might be able to be done directly on the userDrawSelections, but not sure so we're doing a copy
    for (let i = 0; i < userDrawSelectionsKeys.length; i++) {
      userDrawSelectionsCopy[userDrawSelectionsKeys[i]] = userDrawSelections[userDrawSelectionsKeys[i]]
    }
    const selection = userDrawSelections[playerId];
    // if the player doesn't have a drawCount yet, then set it
    if (selection === 0) {
      userDrawSelectionsCopy[playerId] = nextDrawCountOption;
    }
    // if the player has a drawCount and is clicked, remove it
    else {
      userDrawSelectionsCopy[playerId] = 0;
    }
    setUserDrawSelections(userDrawSelectionsCopy);
    determineNextDrawCount(userDrawSelectionsCopy);
  }

  // function that all players have a drawCount
  const checkIfAllPlayersHaveADrawCount = (): boolean => {
    const userDrawSelectionsKeys = Object.keys(userDrawSelections);
    for (let i = 0; i < userDrawSelectionsKeys.length; i++) {
      if (userDrawSelections[userDrawSelectionsKeys[i]] === 0) {
        return false;
      }
    }
    return true;
  }

  const playerSelectionBody = (modalTitle: string) => {
    return (
      <>
      <Modal.Header>
        <Modal.Title>{modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {users.filter((player) => player.id !== currentUser.id).map((player, index) => (
          <Button
            key={index}
            className={selectedPlayerButton === index ? "glow" : ""}
            onClick={() => {
              setSelectedPlayer(player.name);
              setSelectedId(player.id);
              setSelectedPlayerButton(index);
            }}>
            {player.name}: Cards x {player.hand.length}
          </Button>
        ))}
      </Modal.Body>
      <Modal.Header></Modal.Header>
      </>
    )
  }

  const colorSelectionBody = () => {
    return (
      <>
        <Modal.Header>
          <Modal.Title>Select a Color</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Button
            className={`color-picker-modal-button-green${selectedColor === COLOR_GREEN ? ' glow' : ''}`}
            onClick={() => { setSelectedColor(COLOR_GREEN) }}>
            Green
          </Button>
          <Button
            className={`color-picker-modal-button-blue${selectedColor === COLOR_BLUE ? ' glow' : ''}`}
            onClick={() => { setSelectedColor(COLOR_BLUE) }}>
            Blue
          </Button>
          <Button
            className={`color-picker-modal-button-yellow${selectedColor === COLOR_YELLOW ? ' glow' : ''}`}
            onClick={() => { setSelectedColor(COLOR_YELLOW) }}>
            Yellow
          </Button>
          <Button
            className={`color-picker-modal-button-purple${selectedColor === COLOR_PURPLE ? ' glow' : ''}`}
            onClick={() => { setSelectedColor(COLOR_PURPLE) }}>
            Purple
          </Button>
        </Modal.Body>
      </>
    )
  }

  const playerAndColorFooter = () => {
    return (
      <>
        <Modal.Footer>
        {((selectedPlayer.length === 0) && (selectedColor.length === 0)) ?
        <Button variant="secondary" disabled>
          Pick a Player {'&'} Color
        </Button>
        :
        ((selectedPlayer.length === 0)) ?
        <Button variant="secondary" disabled>
          Pick a Player
        </Button>
        :
        ((selectedColor.length === 0)) ?
        <Button variant="secondary" disabled>
          Pick a Color
        </Button>
        :
        <Button
          className={
            selectedColor ===  COLOR_BLUE ? "color-picker-modal-button-blue" :
            selectedColor ===  COLOR_YELLOW ? "color-picker-modal-button-yellow" :
            selectedColor ===  COLOR_PURPLE? "color-picker-modal-button-purple" :
            selectedColor ===  COLOR_GREEN ? "color-picker-modal-button-green" :
            ""}
          variant="secondary"
          onClick={onSet}>
          Confirm Choices
        </Button>
        }
      </Modal.Footer>
      </>
    )
  }

  const colorFooter = () => {
    return (
      <>
        <Modal.Footer>
        {
        ((selectedColor.length === 0)) ?
        <Button variant="secondary" disabled>
          Pick a Color
        </Button>
        :
        <Button
          className={
            selectedColor ===  COLOR_BLUE ? "color-picker-modal-button-blue" :
            selectedColor ===  COLOR_YELLOW ? "color-picker-modal-button-yellow" :
            selectedColor ===  COLOR_PURPLE? "color-picker-modal-button-purple" :
            selectedColor ===  COLOR_GREEN ? "color-picker-modal-button-green" :
            ""}
          variant="secondary"
          onClick={onSet}>
          Confirm Choice
        </Button>
        }
      </Modal.Footer>
      </>
    )
  }

  const ghostPepperModal = () => {
    return (
      <Modal show={show} onHide={onSet} backdrop="static" className="ghost-pepper-modal" animation={false}>
      <Modal.Header>
        <Modal.Title>Set Draw Order</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {users.filter((player) => player.id !== currentUser.id).map((player, index) => (
          <div key={index} className="player-container" onClick={() => { toggleSelectionForId(player.id) }}>
            {userDrawSelections[player.id] != null && userDrawSelections[player.id] !== 0 &&
              <div className="ghost-pepper-spray-modal-draw-count">
                +&nbsp;{userDrawSelections[player.id]}
              </div>}
            <div className="player-name">
              <div>
                {player.name}
              </div>
              <div className="player-card-count">
                Cards x {player.hand.length}
              </div>
            </div>
          </div>
        ))}
      </Modal.Body>
      {colorSelectionBody()}
      <Modal.Footer>
        {(!checkIfAllPlayersHaveADrawCount() && (selectedColor.length === 0)) ?
          <Button variant="secondary" disabled>
            Set Count Order {'&'} Color
          </Button>
          :
          (!checkIfAllPlayersHaveADrawCount()) ?
            <Button variant="secondary" disabled>
              Set Count Order
            </Button>
            :
            ((selectedColor.length === 0)) ?
              <Button variant="secondary" disabled>
                Set Color
              </Button>
              :
              <Button
                className={
                  selectedColor === COLOR_BLUE ? "color-picker-modal-button-blue" :
                  selectedColor === COLOR_YELLOW ? "color-picker-modal-button-yellow" :
                  selectedColor === COLOR_PURPLE ? "color-picker-modal-button-purple" :
                  selectedColor === COLOR_GREEN ? "color-picker-modal-button-green" : ""
                }
                variant="secondary"
                onClick={onSet}>
                Confirm Selections
              </Button>
        }
      </Modal.Footer>
    </Modal>
    )
  }

  const fairPlayModal = () => {
    return (
      <Modal show={show} onHide={onSet} backdrop="static" animation={false}>
        {playerSelectionBody("Select Player to Equalize Cards With")}
        {colorSelectionBody()}
        {playerAndColorFooter()}
      </Modal>
    )
  };

  const gettingHeatedModal = () => {
    return (
      <Modal show={show} onHide={onSet} backdrop="static" animation={false}>
        {playerSelectionBody("Select Player to Draw 2 Cards")}
        {colorSelectionBody()}
        {playerAndColorFooter()}
      </Modal>
    )
  };

  const apocalypseModal = () => {
    return (
      <Modal show={show} onHide={onSet} backdrop="static" animation={false}>
        {colorSelectionBody()}
        {colorFooter()}
      </Modal>
    )
  }

  const colorPickerModal = () => {
    return (
      <Modal show={show} onHide={onSet} backdrop="static" animation={false}>
        {colorSelectionBody()}
        {colorFooter()}
      </Modal>
    )
  }

  const swapModal = () => {
    return (
    <Modal show={show} onHide={onSet} backdrop="static" animation={false}>
      <Modal.Header>
        <Modal.Title>Select a Player to Swap Hands</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {users.filter((player) => player.id !== currentUser.id).map((player, index) => (
          <Button
            key={index}
            className={selectedPlayerButton === index ? "glow" : ""}
            onClick={() => {
              setSelectedPlayer(player.name);
              setSelectedId(player.id);
              setSelectedPlayerButton(index);
            }}>
            {player.name}: Cards x {player.hand.length}
          </Button>
        ))}
      </Modal.Body>
      <Modal.Footer>
      {(selectedPlayer.length === 0) ?
        <Button variant="secondary" disabled>
          Pick a Player
        </Button>
        :
        <Button className="color-picker-modal-button-green" onClick={onSet}>
          Swap with {selectedPlayer}
        </Button>}
      </Modal.Footer>
    </Modal>
    )
  }

  return (
    <>
      {renderModal()}
    </>
  )
};

export default CardActionModal;