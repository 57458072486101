import apocalypseImage from "../assets/heated-cards-front/Black Apocalypse.png";
import adjustSpiceLevelImage from "../assets/heated-cards-front/Black Adjust-Spice-Level.png";
import drawFourImage from "../assets/heated-cards-front/Black Draw-4.png";
import fairPlayImage from "../assets/heated-cards-front/Black Fair-Play.png";
import gettingHeatedImage from "../assets/heated-cards-front/Black Getting-Heated.png";
import ghostPepperSprayImage from "../assets/heated-cards-front/Black Ghost-Pepper-Spray.png";
import noThanksImage from "../assets/heated-cards-front/White No-Thanks.png";

// PURPLE Cards
import purpleTooHotImage from "../assets/heated-cards-front/Purple Too-Hot.png";
import purpleCoolOffImage from "../assets/heated-cards-front/Purple Cool-Off.png";
import purpleDrawTwoImage from "../assets/heated-cards-front/Purple Draw-2.png";
import purpleReverseImage from "../assets/heated-cards-front/Purple Reverse.png";
import purpleSwapImage from "../assets/heated-cards-front/Purple Swap.png";
// Numbers
import purpleZeroImage from "../assets/heated-cards-front/Purple 0.png";
import purpleOneImage from "../assets/heated-cards-front/Purple 1.png";
import purpleTwoImage from "../assets/heated-cards-front/Purple 2.png";
import purpleThreeImage from "../assets/heated-cards-front/Purple 3.png";
import purpleFourImage from "../assets/heated-cards-front/Purple 4.png";
import purpleFiveImage from "../assets/heated-cards-front/Purple 5.png";
import purpleSixImage from "../assets/heated-cards-front/Purple 6.png";
import purpleSevenImage from "../assets/heated-cards-front/Purple 7.png";
import purpleEightImage from "../assets/heated-cards-front/Purple 8.png";
import purpleNineImage from "../assets/heated-cards-front/Purple 9.png";

// GREEN Cards
import greenTooHotImage from "../assets/heated-cards-front/Green Too-Hot.png";
import greenCoolOffImage from "../assets/heated-cards-front/Green Cool-Off.png";
import greenDrawTwoImage from "../assets/heated-cards-front/Green Draw-2.png";
import greenReverseImage from "../assets/heated-cards-front/Green Reverse.png";
import greenSwapImage from "../assets/heated-cards-front/Green Swap.png";
// Numbers
import greenZeroImage from "../assets/heated-cards-front/Green 0.png";
import greenOneImage from "../assets/heated-cards-front/Green 1.png";
import greenTwoImage from "../assets/heated-cards-front/Green 2.png";
import greenThreeImage from "../assets/heated-cards-front/Green 3.png";
import greenFourImage from "../assets/heated-cards-front/Green 4.png";
import greenFiveImage from "../assets/heated-cards-front/Green 5.png";
import greenSixImage from "../assets/heated-cards-front/Green 6.png";
import greenSevenImage from "../assets/heated-cards-front/Green 7.png";
import greenEightImage from "../assets/heated-cards-front/Green 8.png";
import greenNineImage from "../assets/heated-cards-front/Green 9.png";

// YELLOW Cards
import yellowTooHotImage from "../assets/heated-cards-front/Yellow Too-Hot.png";
import yellowCoolOffImage from "../assets/heated-cards-front/Yellow Cool-Off.png";
import yellowDrawTwoImage from "../assets/heated-cards-front/Yellow Draw-2.png";
import yellowReverseImage from "../assets/heated-cards-front/Yellow Reverse.png";
import yellowSwapImage from "../assets/heated-cards-front/Yellow Swap.png";
// Numbers
import yellowZeroImage from "../assets/heated-cards-front/Yellow 0.png";
import yellowOneImage from "../assets/heated-cards-front/Yellow 1.png";
import yellowTwoImage from "../assets/heated-cards-front/Yellow 2.png";
import yellowThreeImage from "../assets/heated-cards-front/Yellow 3.png";
import yellowFourImage from "../assets/heated-cards-front/Yellow 4.png";
import yellowFiveImage from "../assets/heated-cards-front/Yellow 5.png";
import yellowSixImage from "../assets/heated-cards-front/Yellow 6.png";
import yellowSevenImage from "../assets/heated-cards-front/Yellow 7.png";
import yellowEightImage from "../assets/heated-cards-front/Yellow 8.png";
import yellowNineImage from "../assets/heated-cards-front/Yellow 9.png";

// BLUE Cards
import blueTooHotImage from "../assets/heated-cards-front/Blue Too-Hot.png";
import blueCoolOffImage from "../assets/heated-cards-front/Blue Cool-Off.png";
import blueDrawTwoImage from "../assets/heated-cards-front/Blue Draw-2.png";
import blueReverseImage from "../assets/heated-cards-front/Blue Reverse.png";
import blueSwapImage from "../assets/heated-cards-front/Blue Swap.png";
// Numbers
import blueZeroImage from "../assets/heated-cards-front/Blue 0.png";
import blueOneImage from "../assets/heated-cards-front/Blue 1.png";
import blueTwoImage from "../assets/heated-cards-front/Blue 2.png";
import blueThreeImage from "../assets/heated-cards-front/Blue 3.png";
import blueFourImage from "../assets/heated-cards-front/Blue 4.png";
import blueFiveImage from "../assets/heated-cards-front/Blue 5.png";
import blueSixImage from "../assets/heated-cards-front/Blue 6.png";
import blueSevenImage from "../assets/heated-cards-front/Blue 7.png";
import blueEightImage from "../assets/heated-cards-front/Blue 8.png";
import blueNineImage from "../assets/heated-cards-front/Blue 9.png";
import { COLOR_BLUE, COLOR_GREEN, COLOR_PURPLE, COLOR_YELLOW } from "./colors";

// BASE GAME:
// 20 Blue cards – 0 to 9
// 20 Green cards – 0 to 9
// 20 Purple cards – 0 to 9
// 20 Yellow cards – 0 to 9
// 8 COOL OFF cards – 2 each in Blue, Green, Purple and Yellow
// 8 REVERSE cards – 2 each in Blue, Green, Purple and Yellow
// 8 DRAW 2 cards - 2 each in Blue, Green, Purple and Yellow
// 4 TOO HOT cards - 1 each in Blue, Green, Purple and Yellow
// 4 SWAP cards - 1 each in Blue, Green, Purple and Yellow
// 4 ADJUST SPICE LEVEL cards
// 4 DRAW 4 cards

// 4 GETTING HEATED cards
// 4 NO, THANKS cards
// 1 GHOST PEPPER SPRAY card
// 2 APOCALYPSE cards
// 2 FAIR PLAY cards

export type Card = {
    id: string;
    color: string;
    image: string;
    name: string;
    type: string;
    description?: string;
    number?: number;
}

// This function manages all the color cards of the four main colors
const createColorPack = (colorString: typeof COLOR_YELLOW | typeof COLOR_GREEN | typeof COLOR_PURPLE | typeof COLOR_BLUE) => {
    let colorPack: Card[] = []
    let tooHotImage: string;
    let drawTwoImage;
    let reverseImage;
    let coolOffImage;
    let swapImage;
    let numberCardImages: string[] = []

    // These if-blocks handle what images we're going to use
    if (colorString === COLOR_GREEN) {
        tooHotImage = greenTooHotImage
        drawTwoImage = greenDrawTwoImage
        reverseImage = greenReverseImage
        coolOffImage = greenCoolOffImage
        swapImage = greenSwapImage
        numberCardImages = [
            greenZeroImage,
            greenOneImage,
            greenTwoImage,
            greenThreeImage,
            greenFourImage,
            greenFiveImage,
            greenSixImage,
            greenSevenImage,
            greenEightImage,
            greenNineImage
        ]
    }
    else if (colorString === COLOR_YELLOW) {
        tooHotImage = yellowTooHotImage
        drawTwoImage = yellowDrawTwoImage
        reverseImage = yellowReverseImage
        coolOffImage = yellowCoolOffImage
        swapImage = yellowSwapImage
        numberCardImages = [
            yellowZeroImage,
            yellowOneImage,
            yellowTwoImage,
            yellowThreeImage,
            yellowFourImage,
            yellowFiveImage,
            yellowSixImage,
            yellowSevenImage,
            yellowEightImage,
            yellowNineImage
        ]
    }
    else if (colorString === COLOR_BLUE) {
        tooHotImage = blueTooHotImage
        drawTwoImage = blueDrawTwoImage
        reverseImage = blueReverseImage
        coolOffImage = blueCoolOffImage
        swapImage = blueSwapImage
        numberCardImages = [
            blueZeroImage,
            blueOneImage,
            blueTwoImage,
            blueThreeImage,
            blueFourImage,
            blueFiveImage,
            blueSixImage,
            blueSevenImage,
            blueEightImage,
            blueNineImage
        ]
    }
    else {
        tooHotImage = purpleTooHotImage;
        drawTwoImage = purpleDrawTwoImage;
        reverseImage = purpleReverseImage
        coolOffImage = purpleCoolOffImage
        swapImage = purpleSwapImage
        numberCardImages = [
            purpleZeroImage,
            purpleOneImage,
            purpleTwoImage,
            purpleThreeImage,
            purpleFourImage,
            purpleFiveImage,
            purpleSixImage,
            purpleSevenImage,
            purpleEightImage,
            purpleNineImage
        ]
    }

    // 4 TOO HOT cards - 1 each in Blue, Green, Purple and Yellow
    const tooHotCard: Card = {
        id: colorString + "-Too-Hot#1",
        color: colorString,
        type: "Action",
        name: "Too Hot",
        image: tooHotImage,
        description: "Everyone must slap the playing field. Last one to slap must draw 2 cards."
    }
    colorPack.push(tooHotCard);
    // 8 DRAW 2 cards - 2 each in Blue, Green, Purple and Yellow
    const drawTwoCardOne: Card = {
        id: colorString + "-Draw-Two#1",
        color: colorString,
        type: "Action",
        name: "Draw 2",
        image: drawTwoImage,
        description: "The next player must draw 2 cards."
    }
    const drawTwoCardTwo: Card = {
        id: colorString + "-Draw-Two#2",
        color: colorString,
        type: "Action",
        name: "Draw 2",
        image: drawTwoImage,
        description: "The next player must draw 2 cards."
    }
    colorPack = colorPack.concat([drawTwoCardOne, drawTwoCardTwo]);
    // 8 REVERSE cards – 2 each in Blue, Green, Purple and Yellow
    const reverseCardOne: Card = {
        id: colorString + "-Reverse#1",
        color: colorString,
        type: "Action",
        name: "Reverse",
        image: reverseImage,
        description: "Reverse the direction of play."
    }
    const reverseCardTwo: Card = {
        id: colorString + "-Reverse#2",
        color: colorString,
        type: "Action",
        name: "Reverse",
        image: reverseImage,
        description: "Reverse the direction of play."
    }
    colorPack = colorPack.concat([reverseCardOne, reverseCardTwo]);
    // 8 COOL OFF cards – 2 each in Blue, Green, Purple and Yellow
    const coolOffCardOne: Card = {
        id: colorString + "-Cool-Off#1",
        color: colorString,
        type: "Action",
        name: "Cool Off",
        image: coolOffImage,
        description: "Next player must skip their turn."
    }
    const coolOffCardTwo: Card = {
        id: colorString + "-Cool-Off#2",
        color: colorString,
        type: "Action",
        name: "Cool Off",
        image: coolOffImage,
        description: "Next player must skip their turn."
    }
    colorPack = colorPack.concat([coolOffCardOne, coolOffCardTwo]);
    // 4 SWAP cards - 1 each in Blue, Green, Purple and Yellow
    const swapCard: Card = {
        id: colorString + "Swap#1",
        color: colorString,
        type: "Action",
        name: "Swap",
        image: swapImage,
        description: "Swap hands with a player of your choice."
    }
    colorPack.push(swapCard)
    for (let number = 0; number < 10; number++) {
        // 20 Blue cards – 0 to 9
        // 20 Green cards – 0 to 9
        // 20 Purple cards – 0 to 9
        // 20 Yellow cards – 0 to 9
        const numberCardOne: Card = {
            id: colorString + "-" + number.toString() + "#1",
            color: colorString,
            type: "Number",
            name: colorString + " " + number.toString(),
            image: numberCardImages[number],
            number: number
        }
        const numberCardTwo: Card = {
            id: colorString + "-" + number.toString() + "#2",
            color: colorString,
            type: "Number",
            name: colorString + " " + number.toString(),
            image: numberCardImages[number],
            number: number
        }
        colorPack = colorPack.concat([numberCardOne, numberCardTwo])
    }
    return colorPack;

}


const apocalypseOne: Card = {
    id: "Apocalypse#1",
    color: "Black",
    type: "Action",
    name: "Apocalypse",
    image: apocalypseImage,
    description: "Choose a color to continue play. Everyone BUT you must discard 1 card of the chosen color or draw 1 card."
}
const apocalypseTwo: Card = {
    id: "Apocalypse#2",
    color: "Black",
    type: "Action",
    name: "Apocalypse",
    image: apocalypseImage,
    description: "Choose a color to continue play. Everyone BUT you must discard 1 card of the chosen color or draw 1 card."
}


const adjustSpiceLevelOne: Card = {
    id: "Adjust-Spice-Level#1",
    color: "Black",
    type: "Action",
    name: "Adjust Spice Level",
    image: adjustSpiceLevelImage,
    description: "Choose the color that continues play."
}
const adjustSpiceLevelTwo: Card = {
    id: "Adjust-Spice-Level#2",
    color: "Black",
    type: "Action",
    name: "Adjust Spice Level",
    image: adjustSpiceLevelImage,
    description: "Choose the color that continues play."
}
const adjustSpiceLevelThree: Card = {
    id: "Adjust-Spice-Level#3",
    color: "Black",
    type: "Action",
    name: "Adjust Spice Level",
    image: adjustSpiceLevelImage,
    description: "Choose the color that continues play."
}
const adjustSpiceLevelFour: Card = {
    id: "Adjust-Spice-Level#4",
    color: "Black",
    type: "Action",
    name: "Adjust Spice Level",
    image: adjustSpiceLevelImage,
    description: "Choose the color that continues play."
}

const drawFourOne: Card = {
    id: "Draw-Four#1",
    color: "Black",
    type: "Action",
    name: "Draw Four",
    image: drawFourImage,
    description: "The next player must draw 4 cards."
}
const drawFourTwo: Card = {
    id: "Draw-Four#2",
    color: "Black",
    type: "Action",
    name: "Draw Four",
    image: drawFourImage,
    description: "The next player must draw 4 cards."
}
const drawFourThree: Card = {
    id: "Draw-Four#3",
    color: "Black",
    type: "Action",
    name: "Draw Four",
    image: drawFourImage,
    description: "The next player must draw 4 cards."
}
const drawFourFour: Card = {
    id: "Draw-Four#4",
    color: "Black",
    type: "Action",
    name: "Draw Four",
    image: drawFourImage,
    description: "The next player must draw 4 cards."
}

const fairPlayOne: Card = {
    id: "Fair-Play#1",
    color: "Black",
    type: "Action",
    name: "Fair Play",
    image: fairPlayImage,
    description: "Choose a player. The one with more cards discards until both players have the same number of cards."
}
const fairPlayTwo: Card = {
    id: "Fair-Play#2",
    color: "Black",
    type: "Action",
    name: "Fair Play",
    image: fairPlayImage,
    description: "Choose a player. The one with more cards discards until both players have the same number of cards."
}

const gettingHeatedOne: Card = {
    id: "Getting-Heated#1",
    color: "Black",
    type: "Action",
    name: "Getting Heated",
    image: gettingHeatedImage,
    description: "Force any player to draw 2 cards."
}
const gettingHeatedTwo: Card = {
    id: "Getting-Heated#2",
    color: "Black",
    type: "Action",
    name: "Getting Heated",
    image: gettingHeatedImage,
    description: "Force any player to draw 2 cards."
}
const gettingHeatedThree: Card = {
    id: "Getting-Heated#3",
    color: "Black",
    type: "Action",
    name: "Getting Heated",
    image: gettingHeatedImage,
    description: "Force any player to draw 2 cards."
}
const gettingHeatedFour: Card = {
    id: "Getting-Heated#4",
    color: "Black",
    type: "Action",
    name: "Getting Heated",
    image: gettingHeatedImage,
    description: "Force any player to draw 2 cards."
}

const ghostPepperSprayOne: Card = {
    id: "GhostPepperSpray#1",
    color: "Black",
    type: "Action",
    name: "Ghost Pepper Spray",
    image: ghostPepperSprayImage,
    description: "Number all other players in any order, starting from 1. They must draw cards equal to the number they are assigned."
}

const noThanksOne: Card = {
    id: "No-Thanks#1",
    color: "White",
    type: "Action",
    name: "No, Thanks",
    image: noThanksImage,
    description: "Skip your turn or cancel an action card played against you."
}
const noThanksTwo: Card = {
    id: "No-Thanks#2",
    color: "White",
    type: "Action",
    name: "No, Thanks",
    image: noThanksImage,
    description: "Skip your turn or cancel an action card played against you."
}
const noThanksThree: Card = {
    id: "No-Thanks#3",
    color: "White",
    type: "Action",
    name: "No, Thanks",
    image: noThanksImage,
    description: "Skip your turn or cancel an action card played against you."
}
const noThanksFour: Card = {
    id: "No-Thanks#4",
    color: "White",
    type: "Action",
    name: "No, Thanks",
    image: noThanksImage,
    description: "Skip your turn or cancel an action card played against you."
}

let heatedBaseGameDeck: Card[] = [];

// Create all the colored cards like 0-9 and action cards and add them to the deck
const mainColors: (typeof COLOR_PURPLE | typeof COLOR_GREEN | typeof COLOR_BLUE | typeof COLOR_YELLOW)[] = [COLOR_PURPLE, COLOR_GREEN, COLOR_BLUE, COLOR_YELLOW];
for (let i = 0; i < mainColors.length; i++) {
    heatedBaseGameDeck = heatedBaseGameDeck.concat(createColorPack(mainColors[i]))
}

// Add all the special cards
// 2 APOCALYPSE cards
heatedBaseGameDeck = heatedBaseGameDeck.concat([apocalypseOne, apocalypseTwo])
// 4 ADJUST SPICE LEVEL cards
heatedBaseGameDeck = heatedBaseGameDeck.concat([adjustSpiceLevelOne, adjustSpiceLevelTwo, adjustSpiceLevelThree, adjustSpiceLevelFour])
// 2 FAIR PLAY cards
heatedBaseGameDeck = heatedBaseGameDeck.concat([fairPlayOne, fairPlayTwo])
// 1 GHOST PEPPER SPRAY card
heatedBaseGameDeck.push(ghostPepperSprayOne);
// 4 NO, THANKS cards
heatedBaseGameDeck = heatedBaseGameDeck.concat([noThanksOne, noThanksTwo, noThanksThree, noThanksFour]);
// 4 DRAW 4 cards
heatedBaseGameDeck = heatedBaseGameDeck.concat([drawFourOne, drawFourTwo, drawFourThree, drawFourFour]);
// 4 GETTING HEATED cards
heatedBaseGameDeck = heatedBaseGameDeck.concat([gettingHeatedOne, gettingHeatedTwo, gettingHeatedThree, gettingHeatedFour])

export { heatedBaseGameDeck }

// in case we want to test particular cards
const testCards: { [cardName: string]: Card } = {};
for (let i = 0; i < heatedBaseGameDeck.length; i++) {
    let cardName = heatedBaseGameDeck[i].name;
    let containsColor = false;
    for (let c = 0; c < mainColors.length; c++) {
        if (cardName.includes(mainColors[c])) {
            containsColor = true;
        }
    }
    if (!containsColor && heatedBaseGameDeck[i].color !== 'White' && heatedBaseGameDeck[i].color !== "Black") {
        cardName = heatedBaseGameDeck[i].color + " " + heatedBaseGameDeck[i].name
    }
    testCards[cardName] = heatedBaseGameDeck[i]
}

console.log("Test cards", testCards);
export { testCards }