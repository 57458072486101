// import { Button } from "react-bootstrap";
import "./css/currentPlayerHand.css";
import { Card } from '../utils/cards';
import { useEffect, useState } from 'react';
import {
    rectSortingStrategy,
    SortableContext,
} from '@dnd-kit/sortable';

import { SortableCard } from './SortableCard';

type CurrentPlayerHandProps = {
    renderPlayerText: () => string;
    handOfCurrentPlayer: Card[];
    handleCardClick: (card: Card, index: number, cardImgRef: any) => void;
    handleCardReorderingInPlayerHand: (hand: Card[]) => void;
    name: string;
    offeredCards: {
        playerId: string;
        cardId: string;
    }[]
}

function CurrentPlayerHand(props: CurrentPlayerHandProps) {

    const [playerHand, setPlayerHand] = useState(props.handOfCurrentPlayer)

    useEffect(() => {
        setPlayerHand(props.handOfCurrentPlayer)
    }, [props.handOfCurrentPlayer])


    return (
        <div className='current-player-hand'>
            <p className='current-player-text'>{props.name}</p>
            <p className='current-color-and-discard-amount-text'>
                {props.renderPlayerText()}
            </p>
            <div className='current-player-heated-cards'>

                <SortableContext
                    items={playerHand}
                    strategy={rectSortingStrategy}
                >
                    {playerHand.map((card: Card, i: number) => <SortableCard key={card.id} id={card.id} card={card} index={i} handleCardClick={props.handleCardClick} offeredCards={props.offeredCards} />)}
                </SortableContext>

            </div>

        </div>
    )

}

export default CurrentPlayerHand;


